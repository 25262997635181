import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer2';
import Banner from './../Elements/Banner';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg.jpg');

const blogs = [
    {
        slug: 'post-image',
        image: require('./../../images/blog/Blog12.jpeg'),
        title: 'Operation & Maintenance (O & M) – A key to optimum Solar Power Generation',
        description: 'In the world dominated by few industries, one of the growing and promising industries is Renewable Energy industry as it is the need [...]',
        date: '19',
        month: 'Feb',
        year: '2021'
    },
    {
        slug: 'post-image-2',
        image: require('./../../images/blog/Blog22.png'),
        title: 'Do you have a Solar Operation and Maintenance Plan?',
        description: 'Do you have a Solar Operation and Maintenance Plan? Operation and Maintenance of a solar power plant is one of the most crucial parts of any solar [...]',
        date: '19',
        month: 'March',
        year: '2020'
    },
    {
        slug: 'post-image-3',
        image: require('./../../images/blog/Blog3.jpg'),
        title: 'THE RIGHT TOOL FOR ANY ROOFTOP SOLAR POWER PLANT – GeWiSun Products',
        description: 'GeWiSun is a brand of GeWiS Renewpower Pvt. Ltd. GeWiSun holds products and online tools to monitor and analyse power generation of a solar power plant. [...]',
        date: '31',
        month: 'Jan',
        year: '2020'
    }
]

class BlogMasonary extends React.Component {
    
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };

    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="For your roofing and siding, quality we’ll be providing!" pagename="Blog Masonry" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* GALLERY CONTENT START */}
                        <div className="container" style={{margin:'auto' , textAlign:'center'}}>
                        <div className="section-content">
                            <div className="portfolio mfp-gallery news-masonry clearfix row">
                                {/* COLUMNS 1 */}
                                {blogs.map((item, index) => (
                                    <div className="masonry-item col-md-4 col-sm-6">
                                        <div className="blog-post blog-grid date-style-1">
                                            <div className="mt-post-media mt-img-effect zoom-slow">
                                                <NavLink to={`/${item.slug}`}><img src={item.image} alt="" /></NavLink>
                                            </div>
                                            <div className="p-tb20 bg-white">
                                                <div className="mt-post-info">
                                                    <div className="mt-post-meta ">
                                                        <ul>
                                                            <li className="post-date"><strong className="text-primary">{item.date}</strong> <span>{item.month} {item.year}</span></li>
                                                            <li className="post-author">By <NavLink to={`/${item.slug}`}>Admin</NavLink> </li>
                                                            <li className="post-comment"><NavLink to={`/${item.slug}`}>2 comment</NavLink> </li>
                                                        </ul>
                                                    </div>
                                                    <div className="mt-post-title ">
                                                        <h4 className="post-title"><NavLink to={`/${item.slug}`} className=" font-weight-600 m-t0">{item.title}</NavLink></h4>
                                                    </div>
                                                    <div className="mt-post-text">
                                                        <p>{item.description}</p>
                                                    </div>
                                                    <NavLink to={`/${item.slug}`} className="site-button btn-effect ">Read More</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                
                            </div>
                            <ul className="pagination m-tb0">
                                <li><NavLink to={"#"}>«</NavLink></li>
                                <li><NavLink to={"#"}>1</NavLink></li>
                                <li><NavLink to={"#"}>2</NavLink></li>
                                <li><NavLink to={"#"}>3</NavLink></li>
                                <li><NavLink to={"#"}>4</NavLink></li>
                                <li><NavLink to={"#"}>5</NavLink></li>
                                <li><NavLink to={"#"}>»</NavLink></li>
                            </ul>
                        </div>
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 />  
                <Footer />

            </>
        );
    };
};

export default BlogMasonary;