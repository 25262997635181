import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import Banner from '../Elements/Banner';
import '../styles.css';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg.jpg');
var sc_softs = require('./../../images/projects/square/turenkey.jpg');
var sc_softs_2 = require('./../../images/projects/square/energyaudit.jpg');
var sc_softs_3 = require('./../../images/projects/square/designengg.jpg');
var sc_softs_4 = require('./../../images/projects/square/projmng.jpg');
var sc_softs_5 = require('./../../images/projects/square/opmaint.jpg');

class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Services" pagename="SERVICES" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                         
                            <div className="section-content">
                            
                            <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="" style={{ width: '100%'}}>
                                        <img  src={sc_softs} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                        <div style={{ textAlign: 'left' }}>
                                        <h4 style={{ fontWeight: 'bold' }}>EPC (Engineering, Procurement and Construction)</h4>
                                        </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <p>We are a total team of 32 technical experts with an aggeregate experience of installing more than 350 MWp solar power plants.</p>
                                                <p>We undertake end-to-end solar PV installation operations:</p>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}>Detailed site analysis</li>
                                                <li style={{ textAlign: 'left' }}>System sizing and designing based on available roof / vacant area</li>
                                                <li style={{ textAlign: 'left' }}>Solar equipment procurement </li>
                                                <li style={{ textAlign: 'left' }}>Solar plant installation</li>
                                                <li style={{ textAlign: 'left' }}>Operation and maintenance activities</li>
                                                <li style={{ textAlign: 'left' }}>Executing formalities for systems under ‘Net Metering’ </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                       
                                            <img  src={sc_softs_2} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                <div style={{ textAlign: 'left' }}>
                                                <h4 style={{ fontWeight: 'bold' }}>Energy Audit</h4>
                                                </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <p>The cost of energy is usually one of the major cost centers for most of the manufacturing companies. GeWiS Renewpower provides energy audit services to help customers optimize their energy consumption and thus minimise the costs.</p>
                                               <p>GeWiS Renewpower has indigenously developed software tools and products that help in monitoring live energy consumption and provide analytics to optimise consumption.</p>
                                                <p>The typical process involves:</p>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}>Measuring current practice</li>
                                                <li style={{ textAlign: 'left' }}>Compare results with the standard practice</li>
                                                <li style={{ textAlign: 'left' }}>Reflect plan and changes</li>
                                                <li style={{ textAlign: 'left' }}>Re-auditing</li>

                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{ width: '100%'}}>                                       
                                            <img  src={sc_softs_3} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                            <div style={{ textAlign: 'left' }}>
                                            <h4 style={{ fontWeight: 'bold' }}>Design & Engineering</h4>
                                            </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <p>GeWiS Renewpower provides expert designing and engineering services. The process involves:</p>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}>Defining the problem statement</li>
                                                <li style={{ textAlign: 'left' }}>Identifying constraints to the solution</li>
                                                <li style={{ textAlign: 'left' }}>Prototyping solutions in the form of rendered images of solar power plants</li>
                                                <li style={{ textAlign: 'left' }}>Testing to evaluate shadow analysis, generation estimate</li>
                                                <li style={{ textAlign: 'left' }}>Optimising design as per requirement</li>
                                                <li style={{ textAlign: 'left' }}>Communicate solutions to the customer</li>
                                                
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                       
                                            <img  src={sc_softs_4} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                <div style={{ textAlign: 'left' }}>
                                                <h4 style={{ fontWeight: 'bold' }}>Project Management Services </h4>
                                                </div>
                                                <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                    <p>GeWiS Renewpower provides expert project management services. The process involves:</p>
                                                    <ul>
                                                    <li style={{ textAlign: 'left' }}>Expert Consultancy</li>
                                                    <li style={{ textAlign: 'left' }}>Technical Due Diligence</li>
                                                    <li style={{ textAlign: 'left' }}>Project Management</li>
                                                    <li style={{ textAlign: 'left' }}>Quality Assurance and Quality Control</li>
                                                    <li style={{ textAlign: 'left' }}>Testing and Commissioning</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                       
                                            <img  src={sc_softs_5} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                            <div style={{ textAlign: 'left' }}>
                                            <h4 style={{ fontWeight: 'bold' }}>Operation & Maintenance</h4>
                                            </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <p>We offer a complete operations and maintenance packages. Our dedicated operations and maintenance team is in constant touch through our online performance tracking platform.</p>
                                                <p>Our online IoT platform offers unique features like online monitoring of solar power plant performance, and alerts & notifications for predictive data-driven operations and maintenance.</p>
                                                <p>There is an extensive list of various checkpoints that we follow to make sure that plant remains up all the time. The typical process involves:</p>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}>Online monitoring: Daily activity</li>
                                                <li style={{ textAlign: 'left' }}>Cleaning of solar panels: Once every 10-15 days</li>
                                                <li style={{ textAlign: 'left' }}>Physical inspection of electrical connections: Once a month or as required</li>
                                                <li style={{ textAlign: 'left' }}>Checking safety measures at the site: Once a month</li>
                                                <li style={{ textAlign: 'left' }}>Physical inspection of all mechanical fixtures, structures and connections: Once every siz months or as required</li>  
                                                </ul>
                                                <p>We also have a team to address emergency maintenance issues of solar power plants.</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 /> 
                <Footer />
            
                </>
        );
    };
};

export default ProjectCarousel;