import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import Banner from '../Elements/Banner';
import '../styles.css';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg.jpg');

var mecion = require('./../../images/projects/square/rms.jpg');
var rpps = require('./../../images/projects/square/rpps.jpg');
var ws = require('./../../images/projects/square/ws.jpg');



class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Products" pagename="Products" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">


                            <div className="section-content">

                            <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="" style={{ width: '100%'}}>                                       
                                                <img  src={mecion} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                    <div style={{ textAlign: 'left' }}>
                                                    <h4 style={{ fontWeight: 'bold' }}>Remote Monitoring System</h4>
                                                    </div>
                                                <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                    <p>The GeWiSun remote monitoring system receives data from the weather sensor, inverters and meters. The cloud-based system enables consumers to monitor real-time performance of the Solar Plant.</p>
                                                    <p>GeWiSun Remote Monitoring Products:</p>
                                                    <li>Wired Datalogger – usually used for plants located on single roof</li>
                                                    <li>Wireless (WSN) data logger – used for the plant that is spread through multiple roofs or where wiring is not feasible</li>
                                                   
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="" style={{ width: '100%'}}>                                       
                                                <img  src={rpps} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                    <div style={{ textAlign: 'left' }}>
                                                    <h4 style={{ fontWeight: 'bold' }}>Reverse Power Protection System (RPPS)</h4>
                                                    </div>
                                                <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                    <p>The indigenously developed Reverse Power Protection System (RPPS) makes sure the incoming surplus solar power does not go to the grid. This is either PLC or data logger based.</p>
                                                    <p>It senses the load requirement at the consumption point and the solar generation simultaneously on real-time basis. When the power requirement is less than what is being generated, the system reduces its voltage level so that it isn’t fed back to the grid.</p>
                                                    <p>It is usually used for solar power systems that are not installed under the ‘Net Metering’ scheme of Discoms.</p>
                                                   
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="" style={{ width: '100%'}}>                                       
                                                <img  src={ws} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                    <div style={{ textAlign: 'left' }}>
                                                    <h4 style={{ fontWeight: 'bold' }}>Weather Sensors</h4>
                                                    </div>
                                                <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                    <p>The indigenously developed weather monitoring system.</p>
                                                    <p>Weather Sensors</p>
                                                    <p>Analog type – Voltage output (0 – 5 V)</p>
                                                    <li>Radiation sensor (Pyranometer)</li>
                                                    <li>Module temperature sensor</li>
                                                    <li>Air temperature sensor</li>     

                                                    <p>Digital (RS485) type –</p>
                                                    <p>With Analog to RS485 converter –</p>
                                                    <li>Radiation sensor (Pyranometer)</li>
                                                    <li>Module temperature sensor</li>
                                                    <li>Air temperature sensor</li>  

                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 />
                <Footer />
            </>
        );
    };
};

export default ProjectCarousel;