import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: 1,
        title: `Services`,
        icon: require('./../../images/icon/reng.jpg'),
        description: (
            <ul>
                {" "}
                <li>- <strong>Energy Audit</strong></li>
                <li>- <strong>Turnkey EPC</strong></li>
                <li>- <strong>Design & Engineering</strong></li>
                <li>- <strong>Project Management</strong></li>
                <li>- <strong>O & M</strong></li>
            </ul>
        ),
        NavLink:'/Turnkey_EPC',
    },
    {
        count: 2,
        title: 'Products',
        icon: require('./../../images/icon/product.webp'),
        description: (
            <ul>
                {" "}
                <li>- <strong>Remote Monitoring System</strong></li>
                <li>- <strong>Reverse Power Protection System</strong></li>
                <li>- <strong>Weather Sensors</strong></li>           
            </ul>
        ),
        NavLink:'/Products',
    },
    {
        count: 3,
        title: 'Financing Options',
        icon: require('./../../images/icon/BusinessGrowth.png'),
        description: (
            <ul>
                {" "}
                <li>- <strong>Capex</strong></li>
                <li>- <strong>No Investment</strong></li> 
                <li>  </li>
                <li>  </li>           
            </ul>
        ),
        NavLink:'/Financing',
    }
]

var img1 = require('./../../images/background/bg-6.png');

class OurServices extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/masonary.js');
      
    };
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding  p-b50  square_shape1">
                    
                    <div className="section-content">
                        <div className="Service-half-top p-t40  bg-dark bg-moving" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                            <div className="container" style={{margin:'auto' , textAlign:'center'}}>
                                {/* TITLE START */}
                                <div className="section-head text-white">
                                    <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                            <h2 className="text-white text-uppercase sep-line-one ">
                                                <span className="font-weight-300 text-gewis">
                                                    Our
                                                </span>{" "}
                                                Services
                                            </h2>
                                        </div>
                                    </div>
                                        <h4>
                                            We provide support and solutions to
                                            our customers in the areas of
                                            renewable energy,
                                            production and mechanical design
                                        </h4>
                                </div>
                                {/* TITLE END */}
                            </div>
                        </div>
                        <div className="services-half-bottom">
                            <div className="container" style={{margin:'auto'}}>
                                <div className="row">
        <div style={{alignItems:"center",alignContent:"center"}}>
                                    {services.map((item, index) => (                                       
                                        <div className="col-md-3 col-sm-6" key={index}>
                                            <div className="mt-icon-box-wraper m-b30">
                                                <div className="relative icon-count-2 bg-gray p-a30 p-tb50">
                                                    <span className="icon-count-number">{item.count}</span>
                                                    <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                                                        <span className="icon-cell"><img src={item.icon} alt="" loading="lazy" style={{maxWidth:"100%",maxHeight:"100%"}}/></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="mt-tilte m-b25">{item.title}</h4>
                                                        <p>{item.description}</p>                                                 
                                                        <NavLink
                                                            to={item.NavLink}
                                                            className="site-button-link"
                                                            data-hover="Read More"
                                                        >
                                                            Read More{" "}
                                                            <i className="fa fa-angle-right arrow-animation" />
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                     
                                    ))}
        </div>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
            </>
        );
    }
};

export default OurServices;