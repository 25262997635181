import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import SimilarProjects from '../Elements/SimilarProjects';
import Banner from '../Elements/Banner';
import ReactPlayer from 'react-player';

var bnrimg = require('./../../images/banner/5.jpg');

class ProjectDetail extends React.Component {
    
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Helping you and your house become better acquainted." pagename="Project Detail" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                            <div className="project-detail-outer">
                                <div className="m-b0">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/advikb2.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/advikb1.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="m-b30">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="product-block">
                                                <div className="row">
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Plant Capacity:</h4>
                                                        <p>125 kWp</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Plant Location:</h4>
                                                        <p>Bengaluru, Karnataka</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Installed on:</h4>
                                                        <p>GI sheet rooftop of the factory</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Annual Power Generation:</h4>
                                                        <p>177800 kWh</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Carbon dioxide emissions mitigated:</h4>
                                                        <p>3844 tonnes</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="mt-box">
                                                <div className="mt-thum-bx mt-img-overlay1 mt-img-effect">
                                                   <img src={require('./../../images/gallery/s2.jpg')} alt="" />
                                                    {/* <button type="button" className="play-now" data-toggle="modal" data-target="#myModal3">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-detail-containt">
                                    <div className="bg-white text-black">
                                        <h3 className="font-weight-600">Advik Hi-Tech Pvt. Ltd.-Bengaluru</h3>
                                        <p>ADVIK Hi-Tech Private Limited (AHPL) is one of India’s leading & global automotive component manufacturers. The solar plant is installed on the rooftop of its facility located at Bengaluru. The plant has been installed by GeWiS Renewpower.</p>
                                        <p>A 125kWp rooftop solar power plant has been installed on the metal sheet rooftop of the factory. The plant is spread over an area of 1312 square meters.</p>
                                        <p>The solar power plant generates about 17.7 lakh units of energy annually and is taking up about 25% of the factory’s total energy requirement.</p>
                                        <p>The system is installed under the ‘Net Metering’ scheme of BESCOM. In this, the unused power that is generated during daytime is fed to the grid. The units are adjusted in the night hours when solar is not available.</p>
                                        <p>The solar plant is installed under the ‘OPEX’ form of financial model. In this, the system cost is paid by one of GeWiS empanelled investors. AHPL pays to the investor on an approved cost per unit for the number of units generated per month. The investor and AHPL have signed a long term power purchase agreement (PPA) for the same.</p>
                                        <p>The solar power plant helps in reducing about 4 tonnes of carbon emissions annually.</p>
                                        <p>GeWiS is also taking care of the operation and maintenance of the solar power plant. GeWiS has installed the required safety equipment like walkways. These walkways provide easy access to solar plants. A cleaning system has been placed by the GeWiS. The cleaning system comprises of UPVC pipe grid for supplying water on the roof. The pipe consists of nozzle at every 25 mtrs so that panels all over the roof are accessible for cleaning.</p>
                                        <p>The indigenously developed data logger by GeWiS has been set up in the facility. This helps in online monitoring of the solar power plant.</p>
                                        <p>Gewis is the preferred partner of AHPL. We have also installed rooftop solar plant at its Pantnagar facility.</p>


                                        <div className="m-b0">
                                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square" /></div>
                                        </div>
                                        <ul className="social-icons social-square social-darkest m-b0">
                                            <li><NavLink to={"#"} className="fa fa-facebook" /></li>
                                            <li><NavLink to={"#"} className="fa fa-twitter" /></li>
                                            <li><NavLink to={"#"} className="fa fa-linkedin" /></li>
                                            <li><NavLink to={"#"} className="fa fa-rss" /></li>
                                            <li><NavLink to={"#"} className="fa fa-youtube" /></li>
                                            <li><NavLink to={"#"} className="fa fa-instagram" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                    <SimilarProjects/>
                </div>

                <div className="modal fade" id="myModal3" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://www.youtube.com/watch?v=s3A7AK1K2hc' />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default ProjectDetail;