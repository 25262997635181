import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer2';
import LatestBlogs2 from '../Elements/LatestBlogs2';
import BlogComments from '../Elements/BlogComments';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/bgimg.jpg');

class PostImage extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content ">
                <Banner title="Putting a plan to action, to assure your satisfaction!." pagename="Blog" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                        <div className="blog-single-wrap-width">
                            <div className="blog-post date-style-3 blog-detail text-black">           
                                <div className="mt-post-meta ">
                                    <ul>
                                        <li className="post-date"><strong>31 </strong> <span>Jan 2020</span> </li>
                                        <li className="post-author"><NavLink to={"#"}>By <span>Admin</span></NavLink> </li>
                                        <li className="post-category"><NavLink to={"#"}><span>Traditional</span></NavLink> </li>
                                    </ul>
                                </div>
                                <div className="mt-post-title ">
                                    <h3 className="post-title"><NavLink to={"#"} className="font-weight-600">THE RIGHT TOOL FOR ANY ROOFTOP SOLAR POWER PLANT – GeWiSun Products</NavLink></h3>
                                </div>
                                <div className="mt-post-text">
                                    <p>GeWiSun is a brand of GeWiS Renewpower Pvt. Ltd. GeWiSun holds products and online tools to monitor and analyse power generation of a solar power plant.</p>
                                    <p>Recently the complete set of GeWiSun was installed at Victory Precisions Pvt. Ltd, Chakan. Victory Precisions is an aluminum die casting foundry that deals with producing high quality aluminum die casting components for automotive as well as non-automotive applications. For a foundry, the major cost of manufacturing is the cost of energy. The company was looking to procure power through alternate sources other than conventional source to save on the cost of electricity. GeWiS suggested to utilize available rooftop area and install a solar power plant. Based on the available roof space a 415 kWp solar power plant was installed.</p>
                                    <p>Apart from the best-in class modules, inverters and other balance of the system, the company has installed GeWiSun products viz. remote monitoring system, reverse power protection system (RPPS) and weather sensors.</p>
                                    <p>The Remote Monitoring System is capable of analyzing energy consumption and generation, optimizing energy usage and monitoring various other performance parameters. It helps to supervise the functioning of various components of a solar power plant. It is also capable of generating and sending real-time, daily, weekly, monthly and yearly power generation reports to its users.</p>
                                    <p>The ‘Reverse Power Protection System’(RPPS) is also installed at Victory Precisions. The basic purpose of a RPPS is to avoid transmitting the excess power control the grid when there is no net metering (bi-directional meter) installed. The solar power plant works in parallel to the power grid as well as diesel generator (DG). RPPS works as a safety feature for DG synchronization and helps to protect the DG from the excess reverse power. As per our initial load analysis, it occurred that the entire power generated through solar shall be consumed in the factory.</p>
                                    <p>The indigenous developed Weather Sensors are also installed at Victory Precision. It measures solar radiation, wind speed, wind direction, PV panel temperature, ambient temperature, humidity and rainfall. It is one of the most crucial instruments installed in Solar PV Power plants. It is helpful in monitoring the efficiency and performance of the solar power plant. The data from the weather sensors is used to get many insights about the plant operation and possible avenues to increase the plant output.</p>
                                    <p>The weather station communicates with ‘GeWiSun Remote Monitoring System’ that is a SCADA based systems RS485- or MODBUS-TCP (Ethernet) and upload data to cloud using GPRS, Local LAN or WiFi etc.</p>
                                    <p>With the right kind of remote monitoring solutions, weather sensors and other products one can have the luxury to view their plant’s energy information anytime, gather accurate data and take informed decisions that help in maximizing the overall generation of the solar power plant.</p>
                                    <p>In order to ensure that you get the right solution for your system you can rely on our team of experts at GeWiS Renewpower. We can help you choose the product. To know more, simply contact us.</p>
                          
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4">
                                            <div className="mt-box m-b30">
                                                <div className="mt-media">
                                                    <img src={require("./../../images/blog/Blog3.jpg")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="mt-box m-b30">
                                                <div className="mt-media">
                                                    <img src={require("./../../images/blog/Blog32.jpg")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="mt-box m-b30">
                                                <div className="mt-media">
                                                    <img src={require("./../../images/blog/Blog33.jpg")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="autor-post-tag-share bdr-t-1 bdr-solid bdr-gray p-t20">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="widget widget_tag_cloud">
                                                <h4 className="tagcloud">Tags</h4>
                                                <div className="tagcloud">
                                                    <NavLink to={"#"}>First tag</NavLink>&nbsp;
                                                    <NavLink to={"#"}>Second tag</NavLink>&nbsp; 
                                                    <NavLink to={"#"}>Three tag</NavLink>&nbsp; 
                                                    <NavLink to={"#"}>Four tag</NavLink>&nbsp; 
                                                    <NavLink to={"#"}>Five tag</NavLink> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="mt-box">
                                                <div className="clearfix">
                                                    <h4>Share this Post:</h4>
                                                    <div className="widget_social_inks">
                                                        <ul className="social-icons social-md social-square social-dark m-b0">
                                                            <li><NavLink to={"#"} className="fa fa-facebook" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-twitter" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-rss" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-youtube" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-instagram" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>


                <Footer />

            </>
        );
    };
};

export default PostImage;