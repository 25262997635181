import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import SimilarProjects from '../Elements/SimilarProjects';
import Banner from '../Elements/Banner';
import ReactPlayer from 'react-player';

var bnrimg = require('./../../images/banner/5.jpg');

class ProjectDetail extends React.Component {
    
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Helping you and your house become better acquainted." pagename="Project Detail" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                            <div className="project-detail-outer">
                                <div className="m-b0">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/s2.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/s3.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/s1.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-b30">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="product-block">
                                                <div className="row">
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Plant Capacity:</h4>
                                                        <p>400 kWp</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Plant Location:</h4>
                                                        <p>Pune, Maharsahtra</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Installed on:</h4>
                                                        <p>GI sheet rooftop of the factory</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Annual Power Generation:</h4>
                                                        <p>5,65,500 kwh</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Carbon dioxide emissions mitigated:</h4>
                                                        <p>12,300 tonnes</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="mt-box">
                                                <div className="mt-thum-bx mt-img-overlay1 mt-img-effect">
                                                    <img src={require('./../../images/gallery/s1.jpg')} alt="" />
                                                    {/* <button type="button" className="play-now" data-toggle="modal" data-target="#myModal3">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-detail-containt">
                                    <div className="bg-white text-black">
                                        <h3 className="font-weight-600">Sansera Engineering Pvt. Ltd.</h3>
                                        <p>Sansera Engineering is an integrated manufacturer of complex and high quality precision components for automotive and aerospace sectors; primarily supplying to original equipment manufacturers (OEM) in India.</p>
                                        <p>Renewable energy is a part of Sansera’s energy and environmental policy which encouraged them to install solar in their premises. Sansera is an energy extensive industry thus GeWiS Renewpower explored the entire campus thoroughly, examining each roof for its suitability of installing solar.</p>
                                        <p>Out of all the roofs, the machine shop was finalized for solar, as it was the largest single roof, no soot deposition or chimneys nearby and no major heat treatment under the roof, making it ideal for solar.</p>
                                        <p>But there were challenges /Obstructions like air vents and trees casting shadows on the rooftop, to design this plant, GeWiS used shadow analysis tools like skelion and iso-shading from PVsyst to minimize the shading and improve the system performance.</p>
                                        <p>The solar power plant is helping in reducing about 12,300 Tonnes of carbon emissions annually.</p>
                                        <p>The system is installed under CAPEX scheme. In this, Sansera provided the entire investment of the plant.  GeWiS provided turnkey procurement and installation service of entire solar power plant.</p>
                                        <p>GeWiS is also taking care of complete operation and maintenance (O&M) of the solar power plant. GeWiS has installed all the required safety equipment on the site – walkways on the roof for ease of access to the plant. A dedicated UPVC pipe grid is also installed for carrying out hassle free modules’ cleaning activities.</p>
                                        <p>GeWiSun products like data loggers, Pyranometer and RPPS are installed in the facility. RPPS prevents the reverse flow of energy to the grid when there is less energy requirement than generated e.g. during holiday or off peak hours. RPPS curtails the energy during these hours.</p>
                                        <p>A shadow free solar power plant is thus installed by GeWiS which performs best to its capacity during sunny hours.</p>


                                        <div className="m-b0">
                                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square" /></div>
                                        </div>
                                        <ul className="social-icons social-square social-darkest m-b0">
                                            <li><NavLink to={"#"} className="fa fa-facebook" /></li>
                                            <li><NavLink to={"#"} className="fa fa-twitter" /></li>
                                            <li><NavLink to={"#"} className="fa fa-linkedin" /></li>
                                            <li><NavLink to={"#"} className="fa fa-rss" /></li>
                                            <li><NavLink to={"#"} className="fa fa-youtube" /></li>
                                            <li><NavLink to={"#"} className="fa fa-instagram" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                    <SimilarProjects/>
                </div>

                <div className="modal fade" id="myModal3" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://www.youtube.com/watch?v=s3A7AK1K2hc' />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default ProjectDetail;