import React from "react";
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import BlogMasonary from "./Pages/BlogMasonary";
//import Faq from "./Pages/Faq";
// import Error from "./Pages/Error";
import ContactUs from "./Pages/ContactUs";
import ContactUs2 from "./Pages/ContactUs2";
import Careers from "./Pages/Careers";
import Faqs from "./Pages/Faqs";
 import PostImage from "./Pages/PostImage";
 import PostImage2 from "./Pages/PostImage2";
 import PostImage3 from "./Pages/PostImage3";
 import PostImage4 from "./Pages/PostImage4";
 import PostImage5 from "./Pages/PostImage5";
 import PostImage6 from "./Pages/PostImage6";
import ProjectCarousel from "./Pages/ProjectCarousel";
import BlogCarousel from "./Pages/BlogCarousel";
//import ProjectClassic1 from "./Pages/ProjectClassic1";
//import ProjectClassic2 from "./Pages/ProjectClassic2";
import VictoryPrecision from "./Pages/VictoryPrecision";
import AdvikPatnagar from "./Pages/AdvikPatnagar";
import Sansera from "./Pages/Sansera";
import Zamil from "./Pages/ZamilSteel";
import BioradMedisys from "./Pages/BioradMedisys";
import AdvikB from "./Pages/AdvikBangaluru";
import APPL from "./Pages/ApplPune";
import SterlingWilson from "./Pages/SterlingWilson";
import ProjectGrid from "./Pages/ProjectGrid";
//import ProjectMasonary from "./Pages/ProjectMasonary";

import Service_Automation from "./Pages/Service_Automation";
import Service_Manufacturing from "./Pages/Service_Manufacturing";
import Service_Software from "./Pages/Service_Software";
import Engineering_Services from "./Pages/EngineeringServices";
import Project_Management_Services from "./Pages/PmServices";
import Energy_Audit_Services from "./Pages/EauditServices";
import Operation_Maintenance_Services from "./Pages/OperationMaintenance";
import Turnkey_EPC from "./Pages/Turnkey";
// import Video from "./Pages/Video";
import ScrollToTop from "./Common/ScrollToTop";

class Components extends React.Component {
    render() {
        return (
            <HashRouter basename="/">               
                <ScrollToTop />        
                <div className="page-wraper">
                    <Routes>
                        <Route path="/" element={<Home />} />

                        <Route path="/about" element={<About />} />
                        {/* <Route path="/services" element={<Services />} /> */}

                        {/* <Route path="/blog-grid" element={<BlogGrid />} />
                        <Route path="/blog-listing" element={<BlogListing />} /> */}
                        <Route
                            path="/blog-masonary"
                            element={<BlogMasonary />}
                        /> 
                        {/* <Route path="/faq" element={<Faq />} /> */}
                        {/* <Route path="/error" element={<Error />} /> */}
                        <Route path="/contactus" element={<ContactUs />} />
                        <Route path="/contactus2" element={<ContactUs2 />} />
                        <Route path="/career" element={<Careers/>} />
                        <Route path="/faqs" element={<Faqs/>} />
                        <Route path="/Project-Carousel" element={<ProjectCarousel />} />
                        <Route path="/Blog-Carousel" element={<BlogCarousel />} />
                         <Route path="/post-image" element={<PostImage />} />
                         <Route path="/post-image-2" element={<PostImage2 />} />
                         <Route path="/post-image-3" element={<PostImage3 />} />
                         <Route path="/post-image-4" element={<PostImage4 />} />
                         <Route path="/post-image-5" element={<PostImage5 />} />
                         <Route path="/post-image-6" element={<PostImage6 />} />
                         


                        {/* <Route path="/post-gallery" element={<PostGallery />} />
                        <Route path="/post-video" element={<PostVideo />} /> */}
                        <Route
                            path="/Engineering_Services"
                            element={<Engineering_Services />}
                        />   
                         <Route
                            path="/Turnkey_EPC"
                            element={<Turnkey_EPC />}
                        />                     
                        <Route
                            path="/Energy_Audit_Services"
                            element={<Energy_Audit_Services />}
                        />
                        <Route
                            path="/Project_Management_Services"
                            element={<Project_Management_Services />}
                        />
                        <Route
                            path="/Operation_Maintenance_Services"
                            element={<Operation_Maintenance_Services />}
                        />
                        <Route
                            path="/Services"
                            element={<Service_Manufacturing />}
                        />
                         <Route
                            path="/Products"
                            element={<Service_Automation />}
                        />
                        {/* <Route
                            path="/project-classic1"
                            element={<ProjectClassic1 />}
                        /> */}
                        {/* <Route
                            path="/project-classic2"
                            element={<ProjectClassic2 />}
                        /> */}
                        <Route
                            path="/victory-precision"
                            element={<VictoryPrecision />}
                        />
                         <Route
                            path="/advik-patnagar"
                            element={<AdvikPatnagar />}
                        />
                          <Route
                            path="/sansera"
                            element={<Sansera />}
                        />
                        <Route
                            path="/zamil"
                            element={<Zamil />}
                        />
                        <Route
                            path="/biorad"
                            element={<BioradMedisys />}
                        />
                        <Route
                            path="/advik-b"
                            element={<AdvikB />}
                        />
                        <Route
                            path="/appl"
                            element={<APPL />}
                        />
                        <Route
                            path="/Sterling-Wilson"
                            element={<SterlingWilson />}
                        />


                        <Route path="/project-grid" element={<ProjectGrid />} />

                        <Route
                            path="/Financing"
                            element={<Service_Software />}
                        />

                        {/* <Route path="/accordian" element={<Accordian />} /> */}
                        {/* <Route path="/button" element={<Button />} /> */}
                        {/* <Route
                            path="/iconboxstyle"
                            element={<IconBoxStyle />}
                        /> */}
                                     
                    </Routes>
                </div>
            </HashRouter>
        );
    }
}

export default Components;
