import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import Banner from '../Elements/Banner';
import '../styles.css';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg.jpg');
var sc_softs = require('./../../images/projects/square/turenkey.jpg');

class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Services" pagename="SERVICES" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                         
                            <div className="section-content">
                            
                            <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="" style={{ width: '100%'}}>
                                        <img  src={sc_softs} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                        <div style={{ textAlign: 'left' }}>
                                        <h4 style={{ fontWeight: 'bold' }}>EPC (Engineering, Procurement and Construction)</h4>
                                        </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <p>We are a total team of 32 technical experts with an aggeregate experience of installing more than 501 MWp solar power plants.</p>
                                                <p>We undertake end-to-end solar PV installation operations:</p>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}>Detailed site analysis</li>
                                                <li style={{ textAlign: 'left' }}>System sizing and designing based on available roof / vacant area</li>
                                                <li style={{ textAlign: 'left' }}>Solar equipment procurement </li>
                                                <li style={{ textAlign: 'left' }}>Solar plant installation</li>
                                                <li style={{ textAlign: 'left' }}>Operation and maintenance activities</li>
                                                <li style={{ textAlign: 'left' }}>Executing formalities for systems under ‘Net Metering’ </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                 
                            </div>
                            <div className='' style={{margin:'auto', textAlign:'Left', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                <div style={{marginLeft:'6px', marginRight:'6px'}}>
                                <h3>Engineering Services by GeWiS Renewpower</h3>
                                <p>At GeWiS Renewpower, our engineering services are designed to provide you with the highest quality solar energy solutions, tailored to meet the specific needs of each project. Our experienced team of engineers brings a wealth of expertise and innovative thinking to every aspect of solar energy system design and implementation.</p>
                                <h4>Customized Solar Solutions</h4>
                                <p>We understand that every solar project is unique. That’s why we offer customized engineering services that are tailored to your specific energy needs, site conditions, and budget. Our team works closely with you to develop a solution that maximizes energy production and efficiency.</p>
                                <h4>Advanced Design Capabilities</h4>
                                <p>Our engineering services include state-of-the-art design capabilities, utilizing the latest software and technologies to create detailed and accurate system designs. We ensure that every component of your solar energy system is meticulously planned and optimized for performance.</p>
                                <h4>Detailed Feasibility Studies</h4>
                                <p>Before embarking on any project, we conduct comprehensive feasibility studies to assess the viability and potential of your solar energy project. Our studies include detailed analysis of site conditions, energy requirements, and financial considerations, providing you with a clear understanding of your project’s potential.</p>
                                <h4>Robust Engineering Expertise</h4>
                                <p>Our team of engineers is well-versed in all aspects of solar energy systems. We apply our robust engineering expertise to ensure that your solar system is designed and implemented to the highest standards.</p>
                                <h4>Quality Assurance</h4>
                                <p>At GeWiS Renewpower, quality is at the core of everything we do. Our engineering services include rigorous quality assurance processes to ensure that every aspect of your solar energy system meets our high standards of excellence. We are committed to delivering reliable and high-performing solar solutions that stand the test of time.</p>
                                <h4>Why Choose GeWiS Renewpower for Engineering Services?</h4>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>Experienced Engineers: Our team of skilled engineers brings extensive knowledge and experience to every project.</li>
                                        <li>Tailored Solutions: We provide customized engineering services to meet your specific project needs.</li>
                                        <li>Advanced Technology: We use the latest design software and technologies to ensure accurate and efficient system designs.</li>
                                        <li>Comprehensive Support: From feasibility studies to quality assurance, we offer end-to-end support for your solar project.</li>
                                       
                                    </ul>

                                </div>
                            </div>


                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 /> 
                <Footer />
            
                </>
        );
    };
};

export default ProjectCarousel;