import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './../Common/Header';
import Footer from './../Common/Footer2';
import Banner from './../Elements/Banner';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg.jpg');

const projects = [
    {
        slug: 'post-image',
        image: require('./../../images/blog/Blog12.jpeg'),
        title: 'Operation & Maintenance (O & M) – A key to optimum Solar Power Generation',
        //description: 'In the world dominated by few industries, one of the growing and promising industries is Renewable Energy industry as it is the need [...]',
        date: '19',
        month: 'Feb',
        year: '2021'
    },
    {
        slug: 'post-image-2',
        image: require('./../../images/blog/Blog22.png'),
        title: 'Do you have a Solar Operation and Maintenance Plan?',
        
    },
    {
        slug: 'post-image-3',
        image: require('./../../images/blog/Blog3.jpg'),
        title: 'The right tool for any Rooftop Solar Power plant – GeWiSun Products',
    },
    {
        slug: 'post-image-4',
        image: require('./../../images/blog/Blog4.jpg'),
        title: 'Solar Power Plant Design and Installation',
    },
    {
        slug: 'post-image-5',
        image: require('./../../images/blog/Blog5.jpg'),
        title: 'Key Technologies Transforming Solar Power Plants',
    },
    {
        slug: 'post-image-6',
        image: require('./../../images/blog/Blog6.jpg'),
        title: 'Understanding Solar Power Plant Components',
    }
    
]

class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Broad vision. Careful thought. Hand-crafted design." pagename="Blogs" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                            <div className="section-content">
                                <div className="work-carousel-outer">
                                    <OwlCarousel className="owl-carousel work-carousel owl-btn-vertical-center" {...options}>
                                    
                                        {projects.map((item, index) => (
                                            <div key={index} className="item mt-box">
                                                <div className="mt-img-effect mt-img-overlay7">
                                                    <img src={item.image} alt="" />
                                                </div>                                              
                                                <div className="mt-info p-a30">
                                                    <h4 className="m-b20 m-t0"><NavLink to={`/${item.slug}`}>{item.title}</NavLink></h4>
                                                    <p>{item.description}</p>
                                                    <NavLink to={`/${item.slug}`} className="site-button btn-effect">Read More</NavLink>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 /> 
                <Footer />
            </>
        );
    };
};

export default ProjectCarousel;