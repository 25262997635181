import React from 'react';
import { NavLink } from 'react-router-dom';

class Faq extends React.Component {
    render() {
        return (
            <>
                
                <div className="page-content">
               
                    
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-t80 p-b80 inner-page-padding">
                        <div className="container"  style={{margin:'auto'}}>
                            <div className="faq-1 bg-gray  p-a50">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="mt-separator-outer separator-left">
                                        <div className="mt-separator">
                                            <h2 className="text-uppercase sep-line-one ">Current Openings </h2>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                {/* Accordian */}
                                <div className="mt-accordion acc-bg-white" id="accordion5">
                                    <div className="panel mt-panel">
                                        <div className="acod-head acc-actives">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                Purchase Manager/Executive
                  <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseOne5" className="acod-body collapse in">
                                            <div className="acod-content p-tb15">
                                            <p><strong>Roles & Responsibilities: –</strong></p>
                                            <ul>
                                                <li>Develop, implement and drive the procurement strategies for the cost-effective acquisition of materials/services to meet the required needs.</li>
                                                <li>Search, Source and develop potential suppliers/ vendors and maintain a relationship with them.</li>
                                                <li>Negotiate with suppliers on lead-time, cost and quality.</li>
                                                <li>Work collaboratively with suppliers, manufacturers and internal departments relating to the ordering of materials, services and supplies for the company.</li>
                                                <li>Ensure deliverables by vendor meets the expected quality standards.</li>
                                                <li>Coordinate with vendor on continuous basis for  quality improvement.</li>
                                                <li>Ensure commercial, legal and contractual compliance in all the procurement transactions.</li>
                                                <li>Review and manage the supplier base to ensure consistency with the sourcing strategy.</li>
                                                <li>Prepare budget for procurement of materials required for the project and measure budgeted vs actual cost.</li>
                                                <li>Forecast upcoming demands, measure and analyse company expenditures.</li>
                                                <li>Provide updated reports to top Management.</li>
                                                <li>Create monthly performance reviews and reports focusing on overall sourcing requirements.</li>
                                                <li>Develop plans for the effective organization and temporary storage of project materials and general inventories at various company and/or independent warehouse locations.</li>
                                                <li>Maintain accurate records of all stored materials and ensure its safe and secure upkeep.</li>
                                                <li>Coordinate with the Sales, Design and Construction teams for product availability, lead times, and cost roadmaps for key materials.</li>
                                                <li>Keep records of materials under warranty and ensure timely repair/replacement of faulty items.</li>
                                                <li>Monitor and measure supplier’s performance and re-evaluation.</li>
                                                <li>Maintain database of approved suppliers/vendors with price list and negotiated rates.</li>
                                            </ul>
                                            <p><strong>Eligibility Criteria: –</strong></p>
                                            <p>Diploma/BE (Electrical/Mechanical) or Any graduate with 2 years of experience in Solar Industry will be preferred. </p>
                                            <p><strong>Skill set required: –</strong></p>
                                            <ul>
                                                <li>Strong relationship & Negotiation skills</li>
                                                <li>Good knowledge of Vendor development and Vendor management</li>
                                                <li>Time management</li>
                                                <li>Hands on experience in Excel handling </li>
                                            </ul>


              </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                Business Development Executive/Manager
                  <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseTwo5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                            <p><strong>Roles & Responsibilities: –</strong></p>
                                            <ul>
                                                <li>Assess the market and evaluate the market trend through market research.</li>
                                                <li>Identify market opportunities, potential customers and prepare sales/revenue forecast.</li>
                                                <li>Develop and implement strategies to achieve sales target of the company and deliver on annual and quarterly sales/revenue/profit plans.</li>
                                                <li>Generate business leads through business directories, client referrals, networking etc.</li>
                                                <li>Handle all the technical and commercial aspects of Marketing & Sales.</li>
                                                <li>Visit the site to assess the area of installation, power output expectation, budget, etc. during discussion with the customer.</li>
                                                <li>Prepare the cost benefit analysis and accordingly make business proposal.</li>
                                                <li>Work with partners, vendors, company’s engineers and program managers to ensure customer needs are met.</li>
                                                <li>Provide after-sales support, monitor status, and ensure customer satisfaction.</li>
                                                <li>Ensure prompt follow-up with customers for orders, commercial terms, payments, approvals, problem resolution, etc.</li>
                                                <li>Keep abreast of government/corporate policies and guidelines on workplace safety, identification and mitigation of safety hazards, work procedures and guidelines for working at height</li>
                                                <li>Coordinate and participate in promotional activities and trade shows.</li>
                                               
                                            </ul>
                                            <p><strong>Eligibility Criteria: –</strong></p>
                                            <p>BE/B.Tech. (Electrical/Mechanical) </p>
                                            <p><strong>Preferred 2 years of experience in sales & marketing in Solar Industry.</strong></p>
                                            <p><strong>Skill set required: –</strong></p>
                                            <ul>
                                                <li>Planning and effective time management</li>
                                                <li>Strong Communication & Presentation skills</li>
                                                <li>Negotiation skills</li>
                                                <li>Networking and relationship management</li>
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                Design Engineer
                  <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseThree5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                                <p><strong>Roles & Responsibilities: –</strong></p>
                                                    <ul>
                                                        <li>The candidate is responsible for design engineering of Solar PV system for both Ground Mount and Roof Top</li>
                                                        <li>Manage site visits, capacity estimation, drawings, BOM finalization, quantity estimation.</li>
                                                        <li>Adherence of design standards and internal & external requirement</li>
                                                        <li>Preparation of design documents and drawings – SLD, GA, Structure design, Shadow Analysis, configuration of String design.</li>
                                                        <li>Performance review of solar plants</li>
                                                        <li>Research and be updated with latest technology and trends in the solar industry</li>
                                                        <li>Technical support to cross functional team and customer</li>                                                   
                                                    </ul>
                                                    <p><strong>Eligibility Criteria: –</strong></p>
                                                    <p>Diploma in Electrical Engineering, or BE/B.Tech</p>
                                                    <p>Minimum 2 years in Solar design</p>
                                                    
                                                    <p><strong>Skill set required: –</strong></p>
                                                    <ul>
                                                        <li>AUTOCAD /SKETCHUP/PVSYST/MS OFFICE /STAAD/SKELLION</li>                                                                                                          
                                                    </ul>
                                                     <p><strong>Location: –</strong></p>
                                                     <p>Base location will be Pune, however, Candidate must be willing to travel as per the job requirement.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                                Solar Installation Engineer
                  <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFour5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                                <p><strong>Roles & Responsibilities: –</strong></p>
                                                    <ul>
                                                        <li>Making improvements to and offering advice on operational procedures</li>
                                                        <li>Scheduling and Coordinating tight deadlines</li>
                                                        <li>Coordinating daily schedule and labour management</li>
                                                        <li>Ensuring all equipment ordered works to its specifications, continuous checks to ensure plant performs to its specifications</li>
                                                        <li>Creating and Carrying out all test procedures</li>
                                                        <li>Investigating problems, and diagnosing and addressing faults</li>
                                                        <li>Troubleshooting</li>
                                                        <li>Handling Contractors and Sub Contractors</li>
                                                        <li>Strict adherence to HSE policy and safety standards</li>
                                                        <li>Reporting daily progress to the reporting head</li>
                                                        <li>Provide Onsite support for PV system commissioning, power quality analysis</li>
                                                        <li>Review Supplier and vendor data sheets</li>
                                                        <li>Inspection of all material received on-site and keeping an inventory check by maintaining records</li>

                                                    </ul>
                                                    <p><strong>Eligibility Criteria: –</strong></p>
                                                    <p>ITI, Diploma in Electrical Engineering /BE Electrical Engineering</p>
                                                    <p><strong>EXPERIENCE:</strong></p>
                                                    <p>01-03 Years in Solar Industry; should have experience and knowledge of both Rooftop and Ground Mount Installations</p>
                                                    <p>Candidate should have the ability to work in teams and flexible enough to adapt to any contingency</p>
                                                    <p>Must have experience in inspection of material received at Site and Quality assurance; inspection of the electrical goods; should possess a good understanding of the quality of the material received on site</p>
                                                    <p><strong>Skill set required: –</strong></p>
                                                    <ul>
                                                        <li>Project Execution, Quality Management, Troubleshooting, Erection Testing, Operations, Coordination, and Client Relationship Management</li>  
                                                        <li>Knowledge of MS OFFICE is essential</li>                                                                                                        
                                                    </ul>
                                                     <p><strong>Location: –</strong></p>
                                                     <p>Base Location is Pune, however, Candidate will be required to travel as per the job requirement.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel mt-panel">
                                        <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                                Solar PV Design Engineer
                  <span className="indicator"><i className="fa fa-plus" /></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseFive5" className="acod-body collapse">
                                            <div className="acod-content p-tb15">
                                           <p><strong>Roles & Responsibilities: –</strong></p>
                                                    <ul>
                                                        <li>The candidate is responsible for design engineering of Solar PV system for both Ground Mount and Roof Top</li>
                                                        <li>Develop Site SOP’s</li>
                                                        <li>Should be able to independently Prepare and Review design, layout and drawings, capacity SLD, Array layout, GA Drawing, shadow analysis, Invertor fabrication, canopy design and allied drawings for placement and methodology of installation</li>
                                                        <li>Configuration and Sizing of PV Plant</li>
                                                        <li>Design PV Monitoring system</li>
                                                        <li>Testing and Commissioning of plant and solving issues proactively</li>
                                                        <li>Work closely with BD teams, Project teams and ensure deadlines are met</li>
                                                        <li>Should be able to resolve issues with root cause analysis</li>
                                                        <li>Should have design knowledge and experience in HT, LT, DC &AC</li>
                                                    
                                                    </ul>
                                                    <p><strong>Eligibility Criteria: –</strong></p>
                                                    <p>BE/B.Tech, Diploma in Electrical Engineering Preferred </p>
                                                    <p>One to Three years preferred in Electrical Design & Engineering</p>
                                                    <p>Candidate with Solar PV Design experience will be given preference</p>
                                                    <p><strong>Skill set required: –</strong></p>
                                                    <ul>
                                                        <li>AUTOCAD /SKETCHUP/PVSYST/MS OFFICE /STAAD/SKELLION</li>                                                                                                          
                                                    </ul>
                                                     <p><strong>Location: –</strong></p>
                                                     <p>Base location will be Pune, however, Candidate must be willing to travel as per the job requirement.</p>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <p style={{marginTop:'3%'}}>Interested candidates may please send their resume to <a href="mailto:contact@gewisrenew.com"><span data-preserver-spaces="true">contact@gewisrenew.com</span></a> or <a href="mailto:hr@gewisrenew.com">hr@gewisrenew.com&nbsp;</a></p>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

            </>
        );
    }
};

export default Faq;