import React from 'react';
import { NavLink } from 'react-router-dom';

const teamMembers = [
    {
        image: require('./../../images/our-team4/pic3.png'),
        membername: 'Amit Trivedi',
        position: 'Director'
    },
    {
        image: require('./../../images/our-team4/pic1.png'),
        membername: 'Gururaj Kanjiker',
        position: 'Executive Director'
    },
    {
        image: require('./../../images/our-team4/pic3.png'),
        membername: 'Trupti Kulkarni',
        position: 'CFO'
    },
    {
        image: require('./../../images/our-team4/pic2.png'),
        membername: 'Tanuja Sherkar ',
        position: 'Project Manager'
    },
    {
        image: require('./../../images/our-team4/pic3.png'),
        membername: 'Gitanjali Gaikwad',
        position: 'Finances'
    },
    {
        image: require('./../../images/our-team4/pic3.png'),
        membername: 'Pranoti Kindarle',
        position: 'Architect'
    },
    {
        image: require('./../../images/our-team4/pic3.png'),
        membername: 'Vinay Nishad',
        position: 'Tech-Engineer RoofTop'
    },
    {
        image: require('./../../images/our-team4/pic3.png'),
        membername: 'Uttareshwar',
        position: 'Tech-Engineer GroundMount'
    }
]

class Team3 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t10 p-b10 bg-white inner-page-padding">
                <div className="container" style={{margin:"auto"}}>
                     <div className="section-content">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="mt-separator-outer separator-left">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Team</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                        </div>
                        {/* IMAGE Team START */}
                        <div className="section-content">
                        <div className="container">
                            <div className="row">
                                {teamMembers.map((item, index) => (
                                    <div key={index} className="col-md-4 col-sm-6 col-xs-6 col-xs-100pc m-b10   ">
                                        <div className="our-team-1 hover-animation-1">
                                            <div className="profile-image scale-in-center"><img src={item.image} alt="" /></div>
                                            <div className="figcaption">
                                                <h4>{item.membername}</h4>
                                                <h5>{item.position}</h5>
                                                <p>Which is worse, that everyone has his price, or that the price is always so low.</p>
                                                <div className="icons">
                                                    <NavLink to={"https://www.facebook.com/Gewis-Renewpower-Pvt-Ltd-1500429330039239/"} ><i className="fa fa-facebook" /></NavLink>
                                                    <NavLink to={"https://twitter.com/i/flow/login?redirect_after_login=%2FGewisLtd"} > <i className="fa fa-twitter" /></NavLink>
                                                    <NavLink  to={"https://gewisrenew.com/"} > <i className="fa fa-google" /></NavLink>
                                                    <NavLink to={"#"} ><i className="fa fa-rss" /></NavLink>
                                                    <NavLink to={"https://www.youtube.com/channel/UCx0II0Y2qyLf-Qbg1Pehacw"}> <i className="fa fa-youtube" /></NavLink>
                                                    <NavLink to={"https://www.linkedin.com/company/gewis-renew-power"} > <i className="fa fa-linkedin" /></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                               
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Team3;