import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';


const CalculatorSolar = () => {
    // Define state variables to store user inputs and calculation results
    const [powerConsumerType, setPowerConsumerType] = useState(0);
    const [roofType, setRoofType] = useState(0);
    const [roofArea, setRoofArea] = useState(0);
    const [monthlyConsumedUnits, setMonthlyConsumedUnits] = useState(0);
    const [results, setResults] = useState({
        annualPowerGeneration: 0,
        annualCostSaving: 0,
        proposedPlantCapacity: 0,
        annualunits: 0,
        reqplantcap: 0,
        modulearea: 0,
        tariff: 0

    });

    const [formSubmitted, setFormSubmitted] = useState(false); // Track whether form has been submitted
    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Perform calculations here
        const tariff = powerConsumerType;
        const annualunits = monthlyConsumedUnits * 12;
        const reqplantcap = annualunits / roofType;
        const modulearea = roofArea / 100;
        
        let plantcapacity;
       
        if (reqplantcap < modulearea) {
            plantcapacity = reqplantcap;       
        } else {
            plantcapacity = modulearea;
            
        }
        // Round plantcapacity to zero decimal points
        plantcapacity = Math.round(plantcapacity);

        // Example calculations
        const annualPowerGeneration = Math.round(plantcapacity * 1500); 
        const annualCostSaving = Math.round(annualPowerGeneration * powerConsumerType); 
        
        // Update state with calculation results
        setResults({
            proposedPlantCapacity: plantcapacity,
            annualPowerGeneration,
            annualCostSaving,
            tariff       
        });
        setFormSubmitted(true); // Set formSubmitted to true after calculations
    };

    return (
        <div className="section-full mobile-page-padding p-t80 p-b50 square_shape2 bg-cover">
            <div className="section-content">
                <div className="container" style={{margin:'auto' , textAlign:'center'}}>
                    <div className="section-head">
                        <div className="mt-separator-outer separator-center">
                            <div className="mt-separator">
                                <h2 className="text-uppercase sep-line-one">
                                    <span className="font-weight-300 text-gewis">
                                        Solar
                                    </span>{" "}
                                    Calculator
                                </h2>
                            </div>
                        </div>
                            <h4>
                            The solar savings calculator is a great way to start discovering solar for your commercial and industrial spaces. 
                            This tool gives estimates based on information you provide and assists you in determining whether a PV system is for you
                            </h4>
                    </div>
                </div>
                <div className="container" style={{margin:'auto', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                    <div className="row">
                    {/* Form for user inputs */}
                    <div className="col-lg-6 col-md-6">
                        <form onSubmit={handleSubmit}>
                        <div className=''>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                    <label style={{fontSize:"medium", color:"black"}}>Power Consumer Type:</label>
                                    <select
                                        value={powerConsumerType}
                                        onChange={(e) => setPowerConsumerType(e.target.value)}
                                        required
                                    >
                                        <option value="">Select Power Consumer Type</option>
                                        <option value="15">Commercial</option>
                                        <option value="6">Residential</option>
                                        <option value="9">Industrial</option>
                                    </select>
                            </div></div>
                            <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <label style={{fontSize:"medium", color:"black"}}>Roof Type:</label>
                                <select                        
                                    value={roofType}
                                    onChange={(e) => setRoofType(e.target.value)}
                                    required style={{maxWidth:"90%"}}
                                >
                                        <option value="">Select Roof Type</option>
                                        <option value="1400">RCC Roof</option>
                                        <option value="1300">Metal Sheet Roof</option>
                                        <option value="1500">Open Area (Ground)</option>
                                        <option value="1350">Both-RCC Roof and Metal Sheet Roof</option>
                                        <option value="1300">Car Parking Lot</option>
                                </select>
                            </div>
                            </div>
                        </div>
                        <div className=''>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <label style={{fontSize:"medium", color:"black"}}>Roof Area (sq.ft.):</label>
                                <input
                                    type="number"
                                    value={roofArea}
                                    onChange={(e) => setRoofArea(parseFloat(e.target.value))}
                                    required
                                />
                            </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <label style={{fontSize:"medium", color:"black"}}>Monthly Consumed Units:</label>
                                <input
                                    type="number"
                                    value={monthlyConsumedUnits}
                                    onChange={(e) => setMonthlyConsumedUnits(parseFloat(e.target.value))}
                                    required
                                />
                            </div>
                            </div>
                        </div>
                            <div style={{textAlign:"center",margin:"auto"}}>
                            <button className="site-button btn-effect" type="submit" style={{textAlign:"center",margin:"auto"}}>Calculate <FontAwesomeIcon icon={faCalculator} size="1x" /></button>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-6 col-md-6" style={{margin:'auto', marginTop: '3px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                    {/* Display calculation results */}
                    <div>
                        {/* <div><h3>Calculation Results</h3></div> */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator">
                                    <h3 className="text sep-line-one "style={{fontFamily:"Times New Roman"}}>Calculation Results </h3>
                                </div>
                            </div>
                        </div>                        
                        <h4 style={{fontFamily:"Times New Roman"}}>Annual Power Generation: <span style={{color:"#17bbe8"}}>{results.annualPowerGeneration}</span> Units</h4>                      
                        <h4 style={{fontFamily:"Times New Roman"}}>Annual Cost Saving: Rs.<span style={{color:"#17bbe8"}}> {results.annualCostSaving}/- </span></h4>
                        <h4 style={{fontFamily:"Times New Roman"}}>Proposed Plant Capacity: <span style={{color:"#17bbe8"}}>{results.proposedPlantCapacity}</span> kWp</h4>
                    </div>
                    </div>
                    </div>                  
                </div>
                {formSubmitted && (
                <div className="container" style={{margin:"auto", marginTop: "30px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)"}}>
                <div>
                    <h5>Note:</h5>
                    <p>The proposed capacity is derived from your available area and existing consumption pattern. This is an estimated figure.</p>
                    <p>We have considered your monthly tariff as Rs. {results.tariff}/- per unit.</p>
                    <p>For more details please call us on <strong><a href="tel:+91 98220 57058">+91 98220 57058 </a>or <a href="tel:+91 91122 49981">+91 91122 49981</a></strong>. You can write to us on <strong><a href="mailto:contact@gewisrenew.com">contact@gewisrenew.com</a></strong></p>
                </div>
                </div>
                )}
            </div>
        </div>
    );
};

export default CalculatorSolar;
