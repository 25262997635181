import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer2';
import Banner from './../Elements/Banner';
import About4 from './../Elements/About4';
import OurMission3 from "./../Elements/OurMission";
import Specialization4 from './../Elements/Specialization4';
import Team3 from './../Elements/Team3';
import AboutSummary from './../Elements/AboutSummary';

var bnrimg = require('./../../images/banner/bgimg.jpg');

class About extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="About Us" pagename="About Us" bgimage={bnrimg}/>
                    <About4 />                            
                    <OurMission3 />  
                
                </div>

                <Footer />

            </>
        );
    };
};

export default About;