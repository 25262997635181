import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer2";
import Slider from "./../Elements/Slider2";
import AboutSummary from './../Elements/AboutSummary';
import Specialization from "./../Elements/Specialization";
import About from "./../Elements/About2";
import About2 from "./../Elements/About22";
import OurServices2 from "./../Elements/OurServices4";
import OurMission3 from "./../Elements/OurMission";
import OurProject4 from "./../Elements/OurProject3";
import Testimonials from "./../Elements/Testimonials";
import Calculor from "./../Elements/CalculatorSolar";
import ClientLogo from "./../Elements/ClientsLogo";
import Counters from "./../Elements/Counters"

class Home extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">              
                    <Slider />                                 
                    <About2 />
                    <ClientLogo />
                    <AboutSummary />                                                   
                    <OurServices2 />                 
                    <OurProject4 />                                        
                    <Calculor />                  
                    <OurMission3 />                
                </div>
                <Footer />             
            </>
        );
    }
}

export default Home;
