import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import SimilarProjects from '../Elements/SimilarProjects';
import Banner from '../Elements/Banner';
import ReactPlayer from 'react-player';

var bnrimg = require('./../../images/banner/5.jpg');

class ProjectDetail extends React.Component {
    
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Helping you and your house become better acquainted." pagename="Project Detail" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                            <div className="project-detail-outer">
                                <div className="m-b0">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/vp2.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/vp3.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/vp1.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-b30">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="product-block">
                                                <div className="row">
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Plant Capacity:</h4>
                                                        <p>415 kWp</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Plant Location:</h4>
                                                        <p>Pune, Maharsahtra</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Installed on:</h4>
                                                        <p>GI sheet rooftop of the factory</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Annual Power Generation:</h4>
                                                        <p>5,86,100 kwh</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Carbon dioxide emissions mitigated:</h4>
                                                        <p>12,761 tonnes</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="mt-box">
                                                <div className="mt-thum-bx mt-img-overlay1 mt-img-effect">
                                                   <img src={require('./../../images/gallery/s6.jpg')} alt="" />
                                                    {/* <button type="button" className="play-now" data-toggle="modal" data-target="#myModal3">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-detail-containt">
                                    <div className="bg-white text-black">
                                        <h3 className="font-weight-600">Victory Precisions Pvt. Ltd.</h3>
                                        <p>Victory Precisions Pvt. Ltd. is an aluminium die casting foundry and precision machining facility located in Chakan. GeWiS Renewpower has installed a rooftop solar power plant at their factory. </p>
                                        <p>Aluminium die casting is the most energy-intensive industry. The factory consumes more than 22 lakh unit of electricity annually. GeWiS has installed 415 KWp rooftop solar power plant on the metal sheet rooftop of the factory. Owing to the huge energy requirement, the roof is utilized to its maximum extent, even the ridge vents are covered with panels after its structural stability was confirmed. GeWiS recommended the then-latest 355 Wp Polycrystalline half-cut PERC modules which increased the plant capacity by 8%. Along with these modules, Multi MPPT Huawei Inverters with String level Monitoring has been installed. </p>
                                        <p>The solar power plant generates about 5.8 lakh units of electricity annually and is taking up about 26% of the factory’s total energy requirement.</p>
                                        <p>The system is installed under the CAPEX finance scheme. GeWiS has provided turnkey installation of the entire solar power system and assets are handed over to Victory Precision Pvt. ltd. The solar power plant helps in reducing about 13,000 Tonnes of carbon emissions annually.</p>
                                        <p>GeWiS is also taking care of the operation and maintenance of the solar power plant. A dedicated UPVC pipe grid is installed to conduct hassle-free cleaning activities. For safety and aesthetics, the plant is guarded by safety rails and safety lines on its periphery. Also, staircase, crossover ladders, and walkways made of hot-dipped galvanized iron gratings have been installed for safe crossing through the roofs.</p>
                                        <p>The solar power plant is monitored through the online monitoring system developed by GeWiSun. It helps to analyze and control all three sources of energy i.e. solar, grid and diesel generator.</p>
                                        <p>GeWiSun’s ‘Reverse Power Protection System’ (RPPS) has been installed to make sure that Solar and Diesel generator run in sync when there is no grid power. This helps in savings on diesel consumption and cost.
The system is installed under the ‘Net Metering’ scheme of MSEDCL. In this, the unused power that is generated during the daytime is fed to the grid. The units are adjusted in the night hours when solar is not available</p>
                                        
                                        <div className="m-b0">
                                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square" /></div>
                                        </div>
                                        <ul className="social-icons social-square social-darkest m-b0">
                                            <li><NavLink to={"#"} className="fa fa-facebook" /></li>
                                            <li><NavLink to={"#"} className="fa fa-twitter" /></li>
                                            <li><NavLink to={"#"} className="fa fa-linkedin" /></li>
                                            <li><NavLink to={"#"} className="fa fa-rss" /></li>
                                            <li><NavLink to={"#"} className="fa fa-youtube" /></li>
                                            <li><NavLink to={"#"} className="fa fa-instagram" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                    <SimilarProjects/>
                </div>

                <div className="modal fade" id="myModal3" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://www.youtube.com/watch?v=s3A7AK1K2hc' />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default ProjectDetail;