import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var img1 = require("./../../images/bb5.png");
var img2 = require("./../../images/background/bg-4.png");

const OurMission = () => {
  const formRef = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, 
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
        formRef.current, 
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      const userName = formRef.current.user_name.value;
      toast.success(`Thank you ${userName}! We will contact you soon.`);

      formRef.current.reset();
    } catch (error) {
      console.error("EmailJS Error:", error);
      toast.error('Something went wrong. Please try again.');
    }
  };

  return (
    <>
      <div
        className="section-full mobile-page-padding mission-outer-section p-t80 p-b30 bg-gray bg-no-repeat bg-right-center"
        style={{
          backgroundImage: `url(${img1}), url(${img2})`,
        }}
      >
        <div className="section-content">
          <div className="section-head">
            <div className="mt-separator-outer separator-center">
            </div>
          </div>
          <div className="container" style={{ marginLeft: "10%" }}>
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="contact-home1-left bg-gewis p-a30 m-b0">
                <h3 className="text-white m-t0">
                  <span className="font-weight-100">Get In</span> Touch
                </h3>
                <form ref={formRef} onSubmit={sendEmail} >
                  <div className="input input-animate">
                    <label htmlFor="name">Name</label>
                    <input type="text" name="user_name" id="name" placeholder="Name" required />
                    <span className="spin" />
                  </div>
                  <div className="input input-animate">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="user_email" id="email" placeholder="Email" required />
                    <span className="spin" />
                  </div>
                  <div className="input input-animate">
                    <label htmlFor="Phone">Mobile No.</label>
                    <input type="number" name="phone" id="Phone" placeholder="Mobile No." required />
                    <span className="spin" />
                  </div>
                  <div className="input input-animate">
                    <label htmlFor="message">Looking For</label>
                    <textarea name="message" id="message" placeholder="Looking For" required />
                    <span className="spin" />
                  </div>
                  <div className="text-center p-t10">
                    <button name="submit" type="submit" value="Send" className="site-button btn-effect">Submit Now</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default OurMission;
