import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer2";
import Banner from "./../Elements/Banner";
import Faq from '../Elements/Faq';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg.jpg');

class About extends React.Component {
    render() {
        return (
            <>
                <Header />  
              <div className="page-content">
                    <Banner
                        title="Careers"
                        pagename="Careers"
                        bgimage={bnrimg}
                    />                  
                    <Faq />                      
              </div>
                <OurMission3 /> 
                <Footer />
            </>
        );
    }
}

export default About;
