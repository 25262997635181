import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const logos = [
    // { image: require('./../../images/client-logo/mercedes.png') },
    // { image: require('./../../images/client-logo/zamil.png') },
    // { image: require('./../../images/client-logo/oetiker.png') },
    // { image: require('./../../images/client-logo/solvay.png') },
    // { image: require('./../../images/client-logo/sansera.png') },
    // { image: require('./../../images/client-logo/ET.png') },
    // { image: require('./../../images/client-logo/technovision.png') },
    // { image: require('./../../images/client-logo/western.png') },
    // { image: require('./../../images/client-logo/vulkan.png') },
    // { image: require('./../../images/client-logo/advik.png') },
    // { image: require('./../../images/client-logo/appl.png') },
    // { image: require('./../../images/client-logo/nagmagic.png') },
    // { image: require('./../../images/client-logo/neologic.png') },
    // { image: require('./../../images/client-logo/amar.png') },
    // { image: require('./../../images/client-logo/biorad.png') },
    // { image: require('./../../images/client-logo/vp.png') },
    // { image: require('./../../images/client-logo/arofine.png') },
    // { image: require('./../../images/client-logo/AzurePower.png') },
    // { image: require('./../../images/client-logo/adani.png') },
    // { image: require('./../../images/client-logo/enfinity.png') },
    // { image: require('./../../images/client-logo/goldstar.png') },
    // { image: require('./../../images/client-logo/HILD2.png') },
    // { image: require('./../../images/client-logo/JAKSON.png') },
    // { image: require('./../../images/client-logo/jpetrol.png') },
    // { image: require('./../../images/client-logo/NTPC.png') },
    // { image: require('./../../images/client-logo/SW.png') }
    { image: require('./../../images/client-logo/VP.png') },
    { image: require('./../../images/client-logo/mb.JPG') },
    { image: require('./../../images/client-logo/zamil.png') },
    { image: require('./../../images/client-logo/oetiker.png') },
    { image: require('./../../images/client-logo/LBST.jpg') },
    { image: require('./../../images/client-logo/jfe.jpg') },
    { image: require('./../../images/client-logo/neologic.png') },
    { image: require('./../../images/client-logo/biorad.png') },
    { image: require('./../../images/client-logo/advik.png') },
    { image: require('./../../images/client-logo/appl.jpg') },
    { image: require('./../../images/client-logo/TVM.jpg') },
    { image: require('./../../images/client-logo/solvay.png') },
    { image: require('./../../images/client-logo/amar.png') },
    { image: require('./../../images/client-logo/sansera.png') },
    { image: require('./../../images/client-logo/ET.png') },
    { image: require('./../../images/client-logo/HILD2.png') },
    { image: require('./../../images/client-logo/JAKSON.png') },
    { image: require('./../../images/client-logo/jpetrol.png') },
    { image: require('./../../images/client-logo/western.png') },   
    { image: require('./../../images/client-logo/nagmagic.png') },
    { image: require('./../../images/client-logo/arofine.png') },
    { image: require('./../../images/client-logo/AzurePower.png') },
    { image: require('./../../images/client-logo/adani.png') },
    { image: require('./../../images/client-logo/vulkan.jpg') },
    { image: require('./../../images/client-logo/enfinity.png') },
    { image: require('./../../images/client-logo/goldstar.png') },
    { image: require('./../../images/client-logo/NTPC.png') },
    { image: require('./../../images/client-logo/SW.png') }
    
]

class ClientsLogo extends React.Component {
    render() {
        const options = {
            loop:true,
            margin:0,
            autoplay:true,
            nav:false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:2
                },
                480:{
                    items:3
                },			
                767:{
                    items:4
                },
                1000:{
                    items:6
                }
            }
        };
        return (
            <>
                <div className="section-full p-t10 bg-white mobile-page-padding">
                <div className="services-half-section-top">
                        <div className="section-content" style={{margin:'auto'}}>
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center text-wh">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Trusted by</span> Clientele</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}                    
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="section-content">
                            {/* TESTIMONIAL 4 START ON BACKGROUND */}
                            {/* <div className="section-content"> */}
                                <div className="section-content p-tb10 owl-btn-vertical-center">
                                    <OwlCarousel className="owl-carousel home-client-carousel-2" {...options}>
                                        {logos.map((item, index) => (
                                            <div className="item" key={index}>
                                                <div className="ow-client-logo">
                                                    <div className="client-logo client-logo-media">
                                                    <NavLink to={"#"} ><img src={item.image} alt=""/></NavLink></div>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                    
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ClientsLogo;