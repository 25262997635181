import React from "react";
import { NavLink } from "react-router-dom";
var Gewis_logo = require('./../../images/glogo.png');

class Navigation extends React.Component {
    componentDidMount() {
        function loadScript(src) {
            return new Promise(function(resolve, reject) {
                var script = document.createElement("script");
                script.src = src;
                script.addEventListener("load", function() {
                    resolve();
                });
                script.addEventListener("error", function(e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript("./assets/js/masonary.js");
    }

    render() {
        return (
            <>
          
          <div
                    className={
                        this.props.bgcolor !== ""
                            ? `header-nav navbar-collapse collapse ${this.props.bgcolor}`
                            : "header-nav navbar-collapse collapse"
                    } 
                    style={{ marginLeft:0}}
                >
                    <ul className="nav navbar-nav">
                        <li className="active">
                            <NavLink to={"/"}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to={"/about"}>About us</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to={""}>Offerings</NavLink>
                            <ul className="sub-menu">
                                <li>
                                    <NavLink to={"/Services"}>
                                        Services
                                    </NavLink>
                                </li>
                               
                               
                            </ul>
                        </li> */}
                        <li>
                            <NavLink to={""}>Services</NavLink>
                            <ul className="sub-menu">
                            <li>
                                    <NavLink to={"/Turnkey_EPC"}>Turnkey EPC Solution </NavLink>
                                    
                                </li>
                                <li>
                                    <NavLink to={"/Engineering_Services"}>Engineering Services</NavLink>
                                    
                                </li>
                                <li>
                                    <NavLink to={"/Energy_Audit_Services"}>Energy Audit Services</NavLink>
                                    
                                </li>
                                <li>
                                    <NavLink to={"/Project_Management_Services"}>Management_Services</NavLink>                        
                                </li>
                                <li>
                                    <NavLink to={"/Operation_Maintenance_Services"}>Operation_Maintenance</NavLink>                        
                                </li>
                                <li>
                                    <NavLink to={"/Products"}>
                                        Products
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/Financing"}>
                                        Financing Option 
                                    </NavLink>
                                </li>
                            </ul>
                        </li> 
                        <li>
                            <NavLink to={""}>Resources</NavLink>
                            <ul className="sub-menu">
                                <li>
                                    <NavLink to={"/Project-Carousel"}>Case Study</NavLink>
                                    
                                </li>
                                <li>
                                    <NavLink to={"/project-grid"}>Projects</NavLink>
                                    
                                </li>
                                <li>
                                    <NavLink to={"/career"}>Careers</NavLink>                        
                                </li>
                                <li>
                                    <NavLink to={"/faqs"}>FAQ</NavLink>                        
                                </li>
                            </ul>
                        </li>                 
                        <li>
                            <NavLink to={"/Blog-Carousel"}>Blog</NavLink>
                            
                        </li>
                        
                        <li>
                            <NavLink to={"/ContactUs"}>Contact Us</NavLink>
                            
                        </li>
                    </ul>
                </div>
               
            </>
        );
    }
}

export default Navigation;
