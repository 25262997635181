import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import Banner from '../Elements/Banner';
import '../styles.css';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg.jpg');

var sc_soft1 = require('./../../images/projects/square/noinvestment.jpg')
var sc_softs_2 = require('./../../images/projects/square/capex.jpg');

class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Financing Options" pagename="Financing Options" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                            <div className="section-content">
                            
                                    <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="" style={{ width: '100%'}}>                                       
                                                <img  src={sc_softs_2} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                    <div style={{ textAlign: 'left' }}>
                                                    <h4 style={{ fontWeight: 'bold' }}>Capex (Own your plant) Model</h4>
                                                    </div>
                                                <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                    <p>The solar plant is your asset for its entire 25 plant life. Depending on the electricity tariff, the cost of the solar plant gets paid back in 3-4 years. The consumer has complete ownership of the solar plant.</p>
                                                    <p>We will undertake all operation and maintenance responsibilities post installation. Our expert engineers and site operators ensure that the plant runs efficiently and seamlessly.</p>
                                                   
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="" style={{ width: '100%'}}>                                       
                                                <img  src={sc_soft1} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                    <div style={{ textAlign: 'left' }}>
                                                    <h4 style={{ fontWeight: 'bold' }}>No Investment (Build-Own-Operate) Model</h4>
                                                    </div>
                                                <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                    <p>This is CAPEX burden-free ‘light asset’ model. Under this format, the consumer only pays for the generated energy. This model offers a tariff which is 30-45% lower than the grid electricity tariff.</p>
                                                    <p>No upfront CAPEX cost is levied on the consumer. All solar PV plant investment is taken care of by GeWiS or its associates through the life of the plant.</p>
                                                    <p>The customer must sign a predetermined low tariff Power Purchase Agreement (PPA) with the investor and enjoy the seamless benefits of the solar plant.</p>
                                                   
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 /> 
                <Footer />          
                </>
        );
    };
};

export default ProjectCarousel;