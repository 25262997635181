import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import Banner from '../Elements/Banner';
import '../styles.css';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg.jpg');
var sc_softs = require('./../../images/projects/square/turenkey.jpg');
var sc_softs_2 = require('./../../images/projects/square/energyaudit.jpg');
var sc_softs_3 = require('./../../images/projects/square/designengg.jpg');
var sc_softs_4 = require('./../../images/projects/square/projmng.jpg');
var sc_softs_5 = require('./../../images/projects/square/opmaint.jpg');

class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Services" pagename="SERVICES" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                         
                            <div className="section-content">
                            
                                <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="" style={{ width: '100%'}}>                                       
                                            <img  src={sc_softs_4} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                                <div style={{ textAlign: 'left' }}>
                                                <h4 style={{ fontWeight: 'bold' }}>Project Management Services </h4>
                                                </div>
                                                <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                    <p>GeWiS Renewpower provides expert project management services. The process involves:</p>
                                                    <ul>
                                                    <li style={{ textAlign: 'left' }}>Expert Consultancy</li>
                                                    <li style={{ textAlign: 'left' }}>Technical Due Diligence</li>
                                                    <li style={{ textAlign: 'left' }}>Project Management</li>
                                                    <li style={{ textAlign: 'left' }}>Quality Assurance and Quality Control</li>
                                                    <li style={{ textAlign: 'left' }}>Testing and Commissioning</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='' style={{margin:'auto', textAlign:'Left', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                <div style={{marginLeft:'6px', marginRight:'6px'}}>
                                <h3>Project Management Services by GeWiS Renewpower</h3>
                                <p>At GeWiS Renewpower, we provide comprehensive project management services to ensure the seamless execution of your solar energy projects. Our experienced project managers oversee every stage of the project, from initial planning to final execution, ensuring that your project is completed on time, within budget, and to the highest standards of quality.</p>
                                <h4>Comprehensive Planning and Coordination</h4>
                                <p>Our project management services start with thorough planning and coordination. We work closely with our clients to understand their specific needs and project goals. Our team develops detailed project plans that outline all aspects of the project, including timelines, resources, and risk management strategies.</p>
                                <h4>Expert Execution</h4>
                                <p>With a focus on precision and efficiency, our project managers oversee the execution of your solar energy project. We ensure that all activities are carried out according to the project plan, with a keen eye on quality and adherence to industry standards. Our team coordinates with all stakeholders, including contractors, suppliers, and regulatory bodies, to ensure smooth project progression.</p>
                                <h4>Quality Control and Assurance</h4>
                                <p>Quality is at the forefront of our project management approach. We implement rigorous quality control and assurance processes to ensure that every aspect of your project meets our high standards. Our project managers conduct regular inspections and audits to identify and address any issues promptly, ensuring that your project is delivered to the highest quality.</p>
                                <p>Our motto is "Passion for Quality." Quality drives every aspect of our project management. We enforce stringent quality control and assurance measures to ensure that every detail of your project meets our exacting standards. Our project managers perform regular inspections and audits to swiftly identify and resolve any issues, guaranteeing that your project is delivered to the highest quality.</p>
                                <h4>Risk Management</h4>
                                <p>Effective risk management is a critical component of our project management services. Our team identifies potential risks early in the project and develops mitigation strategies to address them. We continuously monitor and manage risks throughout the project lifecycle to ensure minimal impact on project timelines and budgets.</p>
                                <h4>Why Choose GeWiS Renewpower for Project Management?</h4>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>Experienced Project Managers: Our team of skilled project managers brings extensive experience and expertise to your project.</li>
                                        <li>Comprehensive Planning: We develop detailed project schedules  to ensure smooth execution and timely completion.</li>
                                        <li>Quality Focused: Our rigorous quality control processes ensure that your project meets the highest standards.</li>
                                        <li>Effective Risk Management: We proactively identify and mitigate risks to keep your project on track.</li>
                                        <li>Client-Centric Approach: We work closely with our clients to understand their needs and deliver tailored project management solutions.</li>
                                       
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 /> 
                <Footer />
            
                </>
        );
    };
};

export default ProjectCarousel;