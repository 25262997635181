import React from 'react';

var img1 = require('./../../images/background/bg-6.png');
var img2 = require('./../../images/background/line.png');

class AboutSummary extends React.Component {
    // componentDidMount(){
    //     function loadScript(src) {
           
    //       return new Promise(function(resolve, reject){
    //         var script = document.createElement('script');
    //         script.src = src;
    //         script.addEventListener('load', function () {
    //           resolve();
    //         });
    //         script.addEventListener('error', function (e) {
    //           reject(e);
    //         });
    //         document.body.appendChild(script);
    //         document.body.removeChild(script);
    //       })
    //     };
 
    //   loadScript('./assets/js/masonary.js');
      
    // };
    render() {
        return (
            <>
                <div className="section-full p-t10 p-b40 bg-dark bg-repeat square_shape2 inner-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="section-content">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator text-white">
                                    <h1 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Solar solutions</span> for commercial & Industrial Establishments</h1>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="container" style={{margin:'auto'}}>
                        <div className="section-content our-story" >
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        {/* <h4 className="text-uppercase m-b20">2017-18</h4>
                                        <h4 className="m-b20">Museum Expansion</h4> */}
                                        <p>GeWiS Renewpower caters to the growing need of corporations in the Commercial and Industrial sectors to achieve their sustainability goals by providing innovative, bespoke and cost effective Solar solutions.</p>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        {/* <h4 className="text-uppercase m-b20">2017-18</h4>
                                        <h4 className="m-b20">Museum Expansion</h4> */}
                                        <p>GeWiS Renewpower boasts of a multi-disciplinary team with over 503 MWp of solar experience, world-class quality and health & safety processes’ expertise. We are currently providing Solar PV solutions for consumers in India, Middle-East and South-East Asia.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        {/* <h4 className="text-uppercase m-b20">2011-12</h4>
                                        <h4 className="m-b20">Eisenhower Memoria</h4> */}
                                        <p>We also undertake Operation and Maintenance activities of solar power plants.</p>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        {/* <h4 className="text-uppercase m-b20">2011-12</h4>
                                        <h4 className="m-b20">Eisenhower Memoria</h4> */}
                                        <p>We have indigenously developed IMD certified weather sensors, and IoT based data loggers to remotely monitor real-time plant health in line with the ‘Industry 4.0’ and conduct hassle-free maintenance activities.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic7.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase m-b20">2013-14</h4>
                                        <h4 className="m-b20">Columbia Sport Center</h4>
                                        <p>We also undertake Operation and Maintenance activities of solar power plants.</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic6.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase m-b20">2015-16</h4>
                                        <h4 className="m-b20">Park Boathouse</h4>
                                        <p>We also undertake Operation and Maintenance activities of solar power plants.</p>
                                    </div>
                                </div>
                            </div> */}
                           </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutSummary;