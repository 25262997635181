import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer2';
import LatestBlogs2 from '../Elements/LatestBlogs2';
import BlogComments from '../Elements/BlogComments';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/bgimg.jpg');

class PostImage extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content ">
                <Banner title="Putting a plan to action, to assure your satisfaction!." pagename="Blog" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                        <div className="blog-single-wrap-width">
                            <div className="blog-post date-style-3 blog-detail text-black">                           
                                <div className="mt-post-meta ">
                                    <ul>
                                        <li className="post-date"><strong>19 </strong> <span>March 2020</span> </li>
                                        <li className="post-author"><NavLink to={"#"}>By <span>Admin</span></NavLink> </li>
                                        <li className="post-category"><NavLink to={"#"}><span>Traditional</span></NavLink> </li>
                                    </ul>
                                </div>
                                <div className="mt-post-title ">
                                    <h3 className="post-title"><NavLink to={"#"} className="font-weight-600">Do you have a Solar Operation and Maintenance Plan?</NavLink></h3>
                                </div>
                                <div className="mt-post-text">
                                    <p>Operation and Maintenance of a solar power plant is one of the most crucial parts of any solar power system. The delivery of solar power without any interference maintains a stream of economic value generated per square meter of production. Proper servicing is required to ensure optimum performance and reducing risks of plant downtime.</p>
                                    
                                    <p>A well maintained solar power plant can perform at least 30% better than the one that is not. Without proper operation and maintenance, the system warranties may also be void of all warranties.</p>
                                    <p>O&M issues are usually discussed before the installation of a solar power plant. While cleaning of solar modules regularly is one of the main activities, other activities include a physical inspection of solar modules. For that proper safety measures need to be taken, especially if the solar plant is installed on a metal sheet roof. Following is the list of typical safety measures that are taken into account during the system design phase:</p>
                                   <ul>
                                    <li>For metal sheet rooftops, one has to install safety lifelines alongside the roof edge</li>
                                    <li>Walkways to be installed to conduct comfortable movement on the rooftop</li>
                                    <li>The module tables and walkways to be arranged such that each module can be cleaned by a standard length mob</li>
                                    <li>Water taps’ arrangement to be made available at suitable locations to avoid the use of lengthy flexible pipes</li>
                                    <li>Inverters to be placed at such locations where one can easily carry out maintenance activities</li>

                                   </ul>
                                   <p>The performance of the solar power plant is usually affected because of the following reasons:</p>
                                   <p>1. Dust accumulation</p>
                                   <p>2. Physical integrity</p>
                                   <p>3. Module shading</p>
                                   <p>4. Module mismatch</p>

                                    <p>Dust built up over solar panels can reduce the energy output, and reduce any possible savings and revenue, but most importantly reduce the life of the panels. It is essential to clean the panels on a regular basis. It is also crucial to train the cleaning personnel on proper cleaning methods, safety measures and the use of appropriate cleaning tools.</p>
                                    <p>In order to identify the physical integrity of the panels, visual checks are performed. The main issues that can be visually identified with minimum tools are moisture condensation within the PV modules, corrosion of contacts, and delamination of cells. At times, minute hairline cracks may also occur over time. The panels are usually replaced when even one of the issues occur.</p>
                                    <p>Once the system is installed, there is an extensive list of various checkpoints that are usually followed to make sure that the plant remains up all the time.</p>
                                    <p>The typical process involves:</p>
                                    <ul>
                                        <li><p><strong>Cleaning of solar modules:</strong> Once every 10-15 days</p></li>
                                        <li><p><strong>Physical inspection of electrical connections:</strong> Once a month or as required</p></li>
                                        <li><p><strong>Checking safety measures at the site:</strong> Once a month</p></li>
                                        <li><p><strong>Inverter Servicing:</strong> Once every two months or as required</p></li>
                                        <li><p><strong>Physical inspection of all mechanical fixtures, structures and connections:</strong>  Once every six months or as required</p></li>
                                        <li><p><strong>Checking for infestation by vermin:</strong> While cleaning may not be worth the effort here, there are other issues, like the problem of infestation by vermin. Pests like pigeons and rodents begin making their homes beneath system, creating a large mess. This, at times can create a significant amount of damage and potentially even fire. Preventing is the best maintenance. There are various options to prevent this, the easiest of all is to install non-corrosive mesh guards.</p></li>
                                        <li><p><strong>Online monitoring of the solar power plant:</strong> This is a daily activity and it is done via our online performance tracking platform. It helps in conducting predictive data-driven operation and maintenance activities. The online IoT platform offers unique features like
                                            <ul>
                                            <li>Real-time alerts</li>
                                            <li>Monthly production reports</li>
                                            <li>Web-based portal access</li>
                                            <li>Email alerts</li>
                                            <li>Multiple levels of monitoring</li>
                                            <li>Component monitoring</li>
                                            </ul>
                                            </p></li>
                                    </ul>
                                    <p>The increasing use of online monitoring to proactively identify faults in the system helps to assess the financial benefits of O&M by weighing repair costs against the value of energy loss.</p>
                                    <p>With the technological advancements, there are multiple options by which one can optimize, rather increase generation of solar power plants. These include –</p>
                                    <p>1. Using of de-ionized water for cleaning</p>
                                    <p>2. Thermal imaging to find hot spots</p>
                                    <p>3. Tracing the IV curve to determine the output performance and solar efficiency</p>

                                    <p>Thus, proper operation and maintenance activities are required to achieve maximum output of the solar power plant and also to maintain the overall decorum of the system.</p>
                                    <p>GeWiSun has a dedicated team of O&M that can take complete care of your solar power plant. For more details please feel free to contact us.</p>

                                    <div className="row">
                                        <div className="col-md-4 col-sm-4">
                                            <div className="mt-box m-b30">
                                                <div className="mt-media">
                                                    <img src={require("./../../images/blog/Blog2.png")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="mt-box m-b30">
                                                <div className="mt-media">
                                                    <img src={require("./../../images/blog/Blog22.png")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="autor-post-tag-share bdr-t-1 bdr-solid bdr-gray p-t20">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="widget widget_tag_cloud">
                                                <h4 className="tagcloud">Tags</h4>
                                                <div className="tagcloud">
                                                    <NavLink to={"#"}>First tag</NavLink>&nbsp;
                                                    <NavLink to={"#"}>Second tag</NavLink>&nbsp; 
                                                    <NavLink to={"#"}>Three tag</NavLink>&nbsp; 
                                                    <NavLink to={"#"}>Four tag</NavLink>&nbsp; 
                                                    <NavLink to={"#"}>Five tag</NavLink> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="mt-box">
                                                <div className="clearfix">
                                                    <h4>Share this Post:</h4>
                                                    <div className="widget_social_inks">
                                                        <ul className="social-icons social-md social-square social-dark m-b0">
                                                            <li><NavLink to={"#"} className="fa fa-facebook" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-twitter" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-rss" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-youtube" /></li>
                                                            <li><NavLink to={"#"} className="fa fa-instagram" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>
                <Footer />
            </>
        );
    };
};

export default PostImage;