import React from 'react';
import { NavLink } from 'react-router-dom';
import CountUp from 'react-countup';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import { faSolarPanel } from '@fortawesome/free-solid-svg-icons'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'

var bnr2 = require('./../../images/background/bg-4.png');

class About2 extends React.Component {
    render() {
        const options = {
            loop:true,
            autoplay:true,
            margin:30,
            nav:false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                991:{
                    items:1
                }
            }
        };
        return (
            <>
                <div className="section-full p-t20 p-b4 mobile-page-padding" >
              
                    <div className="services-half-section-bottom p-t80 p-b50  bg-secondry bg-white bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${bnr2})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} >
                        <div className="container-fluid" style={{ textAlign:'center'}}>
                            {/* <div className="section-content"> */}
                                <div className="row">                     
                                    <div className="col-md-4 col-sm-6" >
                                        <div className="text-primary mt-icon-box-wraper m-b30">
                                            <span className="icon-md p-t10">
                                                {/* <i className="flaticon-city" /> */}
                                                 <FontAwesomeIcon icon={faSolarPanel} size="3x" /> 
                                            </span>
                                            <div className="icon-content text-light-blue">
                                                <div className="counter font-50 font-weight-600 m-b5"><CountUp end={156} duration={17} />+</div>
                                                <span className="font-16">Projects Done</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6" >
                                        <div className="text-primary mt-icon-box-wraper m-b30">
                                            <span className="icon-md p-t10">
                                                 {/* <i className="flaticon-worker" />     */}
                                                 <FontAwesomeIcon icon={faUserTie} size="3x" />
                                            </span>
                                            <div className="icon-content text-light-blue">
                                                <div className="counter font-50 font-weight-600 m-b5"><CountUp end={376} duration={17} />+</div>
                                                <span className="font-16">Happy Client</span>
                                            </div>
                                        </div>
                                    </div>                                 
                                    <div className="col-md-4 col-sm-6" >
                                        <div className="text-primary mt-icon-box-wraper m-b30">
                                            <span className="icon-md p-t10">
                                                <FontAwesomeIcon icon={faBolt} size="3x" />
                                            </span>
                                            <div className="icon-content text-light-blue">
                                                <div className="counter font-50 font-weight-600 m-b5"><CountUp end={215} duration={15} />MWp</div>
                                                <span className="font-16">Installation of solar systems</span>
                                            </div>
                                        </div>
                                    </div>                                                                
                                </div>                      
                        </div>
                        
                    </div>
                </div>
            </>
        );
    }
};

export default About2;