import React from "react";

var bnr1 = require("./../../images/background/welcome.jpg");

class Specialization extends React.Component {
    render() {
        return (
            <>
                {/* <div className="section-full mobile-page-padding bg-white p-t80 p-b30 bg-cover bg-center bg-no-repeat square_shape1" style={{backgroundImage:"url(" + bnr1 + ")",height: "40%", width: "100%"}}>  */}
                  <div className="section-full p-t40 p-b10 bg-contain bg-center bg-no-repeat" style={{backgroundImage:"url(" + bnr1 + ")",maxWidth:"100%"}} > 
                    <div className="container" style={{ margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* IMAGE CAROUSEL START */}                      
                            <ul>
                            <li>  
                            <h4>GeWiS Renewpower caters to the growing need of corporations in the Commercial and Industrial sectors to achieve their sustainability goals by providing innovative, bespoke and cost effective Solar solutions.</h4>
                            </li>  
                            <li>
                            <h4>GeWiS Renewpower boasts of a multi-disciplinary team with over 350 MWp of solar experience, world-class quality and health & safety processes’ expertise. We are currently providing Solar PV solutions for consumers in India, Middle-East and South-East Asia.</h4>
                            </li>
                            <li>
                            <h4>We have indigenously developed IMD certified weather sensors, and IoT based data loggers to remotely monitor real-time plant health in line with the ‘Industry 4.0’ and conduct hassle-free maintenance activities.</h4>
                            </li>
                            <li>
                            <h4>We also undertake Operation and Maintenance activities of solar power plants.</h4>
                            </li>
                            </ul>

                    </div>
                    {/* <div className="hilite-title text-right p-r50 text-uppercase text-pop-up-top">
                        <strong>Welcome</strong>
                    </div> */}
                </div>
            </>
        );
    }
}

export default Specialization;
