import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import SimilarProjects from '../Elements/SimilarProjects';
import Banner from '../Elements/Banner';
import ReactPlayer from 'react-player';

var bnrimg = require('./../../images/banner/5.jpg');

class ProjectDetail extends React.Component {
    
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Helping you and your house become better acquainted." pagename="Project Detail" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                            <div className="project-detail-outer">
                                <div className="m-b0">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/z1.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/z2.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>                            
                                    </div>
                                </div>
                                <div className="m-b30">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="product-block">
                                                <div className="row">
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Plant Capacity:</h4>
                                                        <p>1000 kWp</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Plant Location:</h4>
                                                        <p>Pune, Maharsahtra</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Installed on:</h4>
                                                        <p>GI sheet rooftop of the factory</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Annual Power Generation:</h4>
                                                        <p>15,29,000 kWh</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Carbon dioxide emissions mitigated:</h4>
                                                        <p>23459 tonnes</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="mt-box">
                                                <div className="mt-thum-bx mt-img-overlay1 mt-img-effect">
                                                    <img src={require('./../../images/projects/square/Zamil.jpg')} alt="" />
                                                    {/* <button type="button" className="play-now" data-toggle="modal" data-target="#myModal3">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-detail-containt">
                                    <div className="bg-white text-black">
                                        <h3 className="font-weight-600">Zamil Steel Buildings India Pvt. Ltd.</h3>
                                        <p>Zamil Steel Buildings India Pvt. Ltd. Is one of the leading PEB manufacturers in India. The plant is located at Ranjangaon, Pune. and has been installed by GeWiS Renewpower.</p>
                                        <p>Steel manufacturing is basically the most energy-intensive industry. The factory consumes more than 32 lakh units of energy annually.</p>
                                        <p>GeWiS have installed 1MWp rooftop solar power plant on the metal sheet rooftop of the factory. The solar plant is installed on the South-West facing roof of the factory.</p>
                                        <p>The solar power plant generates about 15.2 lakh units of energy annually, and is taking up about 47% of the factory’s total energy requirement.</p>
                                        <p>The system is installed under the ‘Net Metering’ scheme of MSEDCL. In this, the unused power that is generated during the daytime is fed to the grid. The units are adjusted in the night hours when solar is not available.</p>
                                        <p>The system is installed under OPEX scheme. In this, the system cost is paid by one of GeWiS empanelled investors. Zamil Steel pays to the investor on an approved cost per unit for the number of units generated per month as per signed long term power purchase agreement (PPA).</p>
                                        <p>The solar power plant helps in reducing about 23 thousand Tonnes of carbon emissions annually.</p>
                                        <p>GeWiS is also taking care of the operation and maintenance of the solar power plant. GeWiS has installed all the required safety equipment like a ladder, walkways for ease of access. A dedicated UPVC pipe grid is also installed to conduct smooth cleaning activities.</p>
                                        <p>The solar power plant is monitored online through the online monitoring system developed by GeWiSun.</p>            
                                        <p>GeWiSun’s ‘Reverse Power Protection System’ (RPPS) has been used to make sure that Solar and Diesel generator run in sync when there is no grid power. This helps in reducing the diesel requirement.</p>
                                        <p>Zamil Steel is India’s first PEB Company to adopt solar power for its operations.</p>

                                        <div className="m-b0">
                                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square" /></div>
                                        </div>
                                        <ul className="social-icons social-square social-darkest m-b0">
                                            <li><NavLink to={"#"} className="fa fa-facebook" /></li>
                                            <li><NavLink to={"#"} className="fa fa-twitter" /></li>
                                            <li><NavLink to={"#"} className="fa fa-linkedin" /></li>
                                            <li><NavLink to={"#"} className="fa fa-rss" /></li>
                                            <li><NavLink to={"#"} className="fa fa-youtube" /></li>
                                            <li><NavLink to={"#"} className="fa fa-instagram" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                    <SimilarProjects/>
                </div>

                <div className="modal fade" id="myModal3" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://www.youtube.com/watch?v=s3A7AK1K2hc' />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default ProjectDetail;