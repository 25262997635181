import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer2';
import Banner from './../Elements/Banner';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg.jpg');

const filters = [
    { label: "Ground Mount", filter: ".cat-2" },
    { label: "Roof Top", filter: ".cat-1" },
    
];

const projects = [
    {
        thumb: require('./../../images/projects/Biorad.jpg'),
        image: require('./../../images/projects/Biorad.jpg'),
        title: 'Biorad Medisys',
        description: `Sus,Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/victory.jpg'),
        image: require('./../../images/projects/victory.jpg'),
        title: 'Victory Precisions Pvt. Ltd.',
        description: `Chakan,Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/sansera.jpg'),
        image: require('./../../images/projects/sansera.jpg'),
        title: 'Sansera Engineering Pvt Ltd.',
        description: `Chakan,Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/azur.jpg'),
        image: require('./../../images/projects/azur.jpg'),
        title: 'Azure Power',
        description: `Bap-Rajasthan`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/enfinity.jpg'),
        image: require('./../../images/projects/enfinity.jpg'),
        title: 'Enfinity Global',
        description: `Maharashtra`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/sonkhed.jpg'),
        image: require('./../../images/projects/sonkhed.jpg'),
        title: 'Tepsol',
        description: `Nanded, Maharashtra`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/advik_b.jpg'),
        image: require('./../../images/projects/advik_b.jpg'),
        title: 'Advik Hi-Tech Pvt. Ltd.',
        description: `Bengaluru`,
        filter: 'cat-1'
    },   
    {
        thumb: require('./../../images/projects/appl.jpg'),
        image: require('./../../images/projects/appl.jpg'),
        title: 'APPL GOR Plastics India Pvt. Ltd.',
        description: `Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/zamil.jpg'),
        image: require('./../../images/projects/zamil.jpg'),
        title: 'Zamil Steel Buildings India Pvt. Ltd.',
        description: `Ranjangaon,Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/ongoing.jpeg'),
        image: require('./../../images/projects/ongoing.jpeg'),
        title: 'NTPC RE Park',
        description: `Khavda,Bhuj`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/ongoing.jpeg'),
        image: require('./../../images/projects/ongoing.jpeg'),
        title: 'Adani Green Energy Ltd',
        description: `Khavda,Bhuj`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/ongoing.jpeg'),
        image: require('./../../images/projects/ongoing.jpeg'),
        title: 'Azure Power',
        description: `Bap-Rajastan`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/awl.jpg'),
        image: require('./../../images/projects/awl.jpg'),
        title: 'AWL',
        description: `Haldia, WB`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/ongoing.jpeg'),
        image: require('./../../images/projects/ongoing.jpeg'),
        title: 'Goldi Solar Pvt Ltd',
        description: `Sachi-MP`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/CSMI.jpg'),
        image: require('./../../images/projects/CSMI.jpg'),
        title: 'CSMI Airport',
        description: `Mumbai,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/lbst.jpg'),
        image: require('./../../images/projects/lbst.jpg'),
        title: 'LBST Pvt Ltd',
        description: `Haldia, WB`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/TVM.jpg'),
        image: require('./../../images/projects/TVM.jpg'),
        title: 'Technovision Auto components Pvt. Ltd',
        description: `Jaysingpur,Kolhapur`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/advik.png'),
        image: require('./../../images/projects/advik.png'),
        title: 'ADVIK Hi-tech Pvt. Ltd.',
        description: `Pantnagar,Uttarakhand`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/neologic.png'),
        image: require('./../../images/projects/neologic.png'),
        title: 'Neologic Engineers Pvt. Ltd.',
        description: `Pune,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/JFE.jpg'),
        image: require('./../../images/projects/JFE.jpg'),
        title: 'JFE SHOJI STEEL INDIA PVT LTD',
        description: `Pune,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/ASTP.jpg'),
        image: require('./../../images/projects/ASTP.jpg'),
        title: 'Amar Sadanand Tech park',
        description: `Baner,pune,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'HILD ENERGY PVT. LTD.',
        description: `Jaisalmer- Rajasthan`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'NTPC',
        description: `Kota- Rajasthan`,
        filter: 'cat-2'
    },
    
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Adani Power (through Iconic Electric Power Pvt. Ltd.)',
        description: `Kothagudem-Telangana`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'JK Petroenergy Pvt. Ltd.',
        description: `Koppal, Karnataka`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/banerone.jpeg'),
        image: require('./../../images/projects/banerone.jpeg'),
        title: 'Baner One',
        description: `Baner-Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'AMAR BUSINESS ZONE',
        description: `Baner-Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'AMAR TECH CENTER',
        description: `Vimannagar-Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/atp.jpeg'),
        image: require('./../../images/projects/atp.jpeg'),
        title: 'AMAR TECH PARK',
        description: `Baner-Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/ap81.jpeg'),
        image: require('./../../images/projects/ap81.jpeg'),
        title: 'AMAR PRISTINE 81',
        description: `Mundawa-Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Amar Business Bay',
        description: `Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Mercedes Benz India Pvt. Ltd.',
        description: `Chakan,Pune,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Vulkan Technologies Ltd.',
        description: `Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'SVF Pune',
        description: `Pune`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Solvay Specialties India Private Limited',
        description: `Panoli, Gujarat ,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Oetiker India Pvt. Ltd.',
        description: `Khalapur,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Vulkan Technologies Ltd.',
        description: `Pirangut,Pune,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Western Heat & Forge  Pvt. Ltd.',
        description: `Chakan,Pune,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'KK Nag Pvt. Ltd.',
        description: `Sanaswadi,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Arofine Polymers Pvt. Ltd.',
        description: `Chakan,Pune,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Jivdani Devi Mandir Trust',
        description: `Virar,Mumbai,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Dakshana Foundation',
        description: `Rajgurunagar,Pune,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Engineering Technologies Pvt. Ltd.',
        description: `Sangli, Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Monte Ville',
        description: `Pune,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Shivtirth Society',
        description: `Pune,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Marol-2-Petrol Pump',
        description: `Andheri,Mumbai,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Baxi Singh Petrol Pump',
        description: `Kurla,Mumbai,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'NHTC-Petrol Pump',
        description: `Chembur,Mumbai,Maharashtra`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'CrystalStar Landscapes LLP',
        description: `Pune,Maharashtra`,
        filter: 'cat-1'
    }
    
]

class ProjectGrid extends React.Component {
    
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };
    
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Always dedicated and devoted" pagename="Project Grid" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                    <div className="section-content" style={{margin:'auto'}}>
                       
                            {/* PAGINATION START */}
                            <div className="filter-wrap p-b30 text-center">
                                <ul className="filter-navigation inline-navigation masonry-filter link-style  text-uppercase">
                                    {filters.map((item, index) => (
                                    <li key={index}><NavLink to={"#"} data-filter={item.filter} data-hover={item.label}>{item.label}</NavLink></li>
                                    ))} 
                                    <li className="active"><NavLink to={"#"} data-filter="*" data-hover="All">All</NavLink></li>        
                                                               
                                </ul>
                            </div>
                            {/* PAGINATION END */}
                            {/* GALLERY CONTENT START */}
                            <div className="portfolio-wrap mfp-gallery work-grid row clearfix" style={{margin:'auto'}}>
                                {projects.map((item, index) => (
                                    <div key={index} className={`${item.filter} masonry-item col-md-4 col-sm-6 m-b30`}>
                                        <div className="image-effect-two hover-shadow">
                                            <img src={item.thumb} alt="" style={{ maxWidth: '80%', maxHeight: '80%'}}/>
                                            <div className="figcaption">
                                                <h4 className="mt-tilte">{item.title}</h4>
                                                <p>{item.description}</p>                                             
                                                <a className="mfp-link" href={item.image}>
                                                    <i className="fa fa-arrows-alt" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                
                            </div>
                            {/* GALLERY CONTENT END */}
                            <div className="m-t50 text-center">
                                <NavLink to={"#"} className="site-button btn-effect">Load More</NavLink>
                            </div>
                       
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 /> 
                <Footer />
            </>
        );
    };
};

export default ProjectGrid;