import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import Banner from '../Elements/Banner';
import '../styles.css';
import OurMission3 from "./../Elements/OurMission";

var bnrimg = require('./../../images/banner/bgimg.jpg');
var sc_softs = require('./../../images/projects/square/turenkey.jpg');

class ProjectCarousel extends React.Component {
    render() {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Services" pagename="SERVICES" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                         
                            <div className="section-content">
                            
                            <div className='' style={{margin:'auto', textAlign:'center', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}} >
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="" style={{ width: '100%'}}>
                                        <img  src={sc_softs} alt="" style={{ width: '60%', height: 'auto', maxWidth: '100%' }}></img>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className=" " style={{textAlign: 'left', marginLeft: '20px'}}>
                                        <div style={{ textAlign: 'left' }}>
                                        <h4 style={{ fontWeight: 'bold' }}>EPC (Engineering, Procurement and Construction)</h4>
                                        </div>
                                            <div className="uvc-sub-heading ult-responsive" data-ultimate-target=".uvc-heading.ultimate-heading-1151648699ccc9e14 .uvc-sub-heading " data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontWeight: 'normal' }}>
                                                <p>We are a total team of 32 technical experts with an aggeregate experience of installing more than 501 MWp solar power plants.</p>
                                                <p>We undertake end-to-end solar PV installation operations:</p>
                                                <ul>
                                                <li style={{ textAlign: 'left' }}>Detailed site analysis</li>
                                                <li style={{ textAlign: 'left' }}>System sizing and designing based on available roof / vacant area</li>
                                                <li style={{ textAlign: 'left' }}>Solar equipment procurement </li>
                                                <li style={{ textAlign: 'left' }}>Solar plant installation</li>
                                                <li style={{ textAlign: 'left' }}>Operation and maintenance activities</li>
                                                <li style={{ textAlign: 'left' }}>Executing formalities for systems under ‘Net Metering’ </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                 
                            </div>
                            <div className='' style={{margin:'auto', textAlign:'Left', marginTop: '30px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'}}>
                                <div style={{marginLeft:'6px', marginRight:'6px'}}>
                                <h3>Turnkey EPC Solutions by GeWiS Renewpower</h3>
                                <p>At GeWiS Renewpower, we specialize in providing comprehensive turnkey EPC (Engineering, Procurement, and Construction) solutions tailored to meet the specific needs of our clients. Our end-to-end services ensure that your solar projects are executed with precision, efficiency, and excellence.</p>
                                <h4>Comprehensive Project Management</h4>
                                <p>Our turnkey EPC services encompass every aspect of your solar energy project, from initial planning and design to final installation and commissioning. We take pride in delivering projects on time and within budget, ensuring optimal performance and long-term reliability.</p>
                                <h4>Expert Engineering and Design</h4>
                                <p>Our team of experienced engineers and designers works closely with you to create customized solar solutions that maximize energy output and efficiency. We utilize the latest technologies and industry best practices to ensure your solar system is designed to meet your specific energy needs and site conditions.</p>
                                <h4>Quality Procurement</h4>
                                <p>We source high-quality materials and components from trusted suppliers to ensure the durability and performance of your solar energy system. Our procurement process is designed to meet the highest standards of quality and reliability, giving you peace of mind that your investment is built to last.</p>
                                <h4>Professional Construction</h4>
                                <p>Our skilled construction team is dedicated to executing the installation with precision and attention to detail. We follow stringent safety protocols and quality control measures to ensure that every aspect of the construction process is completed to the highest standards.</p>
                                <h4>Commissioning and Maintenance</h4>
                                <p>Once the installation is complete, we perform thorough testing and commissioning to ensure your solar system operates at peak efficiency. Our services don’t stop there; we also offer ongoing maintenance and support to keep your system running smoothly and efficiently throughout its lifespan.</p>
                                <p>After the installation is finished, we conduct comprehensive testing and commissioning to verify that your solar system operates at optimal efficiency. Our commitment extends beyond this, as we provide continuous maintenance and support to ensure your system remains effective and runs smoothly throughout its entire lifespan.</p>
                                <h4>Why Choose GeWiS Renewpower?</h4>
                                    <ul style={{marginLeft:'5%'}}>
                                        <li>Experienced Team: Our team of experts brings extensive experience and knowledge to every project, ensuring successful outcomes.</li>
                                        <li>Customized Solutions: We tailor our services to meet your specific needs and project requirements.</li>
                                        <li>Quality Assurance: We adhere to strict quality standards to deliver reliable and high-performing solar energy systems.</li>
                                        <li>End-to-End Service: From Pre engineering  to post-installation support, we provide a seamless experience for our clients.</li>
                                    </ul>


                                </div>
                            </div>


                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>
                <OurMission3 /> 
                <Footer />
            
                </>
        );
    };
};

export default ProjectCarousel;