import React, { useRef, useState } from 'react';
// import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer2';
import Banner from './../Elements/Banner';
import GoogleMapReact from 'google-map-react';
//import '../contactuscss.css';

var bnrimg = require('./../../images/banner/contactus.jpg');

const AnyReactComponent = ({ text }) => <div>{text}</div>;
    
function ContactForm() {
        //const formRef = useRef();
        const [formData, setFormData] = useState({
            name: '',
            email: '',
            message: ''
          });
        
          const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
          };
        
          const handleSubmit = async (e) => {
            e.preventDefault();
        
            try {
              const response = await fetch('/send-emails', { // Updated API endpoint
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
              });
        
              if (response.ok) {
                alert('Message sent successfully!');
                setFormData({ name: '', email: '', message: '' });
              } else {
                alert('Failed to send message. Please try again later.');
              }
            } catch (error) {
              console.error('Error sending message:', error);
              alert('Failed to send message. Please try after sometime.');
            }
          };

        return (
            <>
                <Header />
                <div className="page-content">
                {/* <div className="col-lg-12 col-md-8 col-sm-6"> */}
                                    <Banner title="We're here for you" pagename="Contact Us" bgimage={bnrimg}/>
            
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* LOCATION BLOCK*/}
                        <div className="container">
                            <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one ">Testing......</h2> 
                            </div>
                            {/* GOOGLE MAP & CONTACT FORM */}
                            <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                    <div className="col-md-8 col-sm-6">
                                        {/* <form className="contact-form cons-contact-form" method="post" onSubmit={this.handleSubmit} > */}
                                        <form onSubmit={handleSubmit}>
                                            <div className="contact-one m-b30">
                                                {/* TITLE START */}
                                                <div className="section-head">
                                                    <div className="mt-separator-outer separator-left">
                                                        <div className="mt-separator">
                                                            <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Get</span> In touch</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* TITLE END */}
                                                {/* <div className="form-group">
                                                    <input name="name" type="text" required className="form-control" placeholder="Name" value={formState.name || ''} onChange={changeHandler} />
                                                </div>
                                                <div className="form-group">
                                                    <input name="email" type="text" className="form-control" required placeholder="Email" value={formState.email || ''} onChange={changeHandler} />
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" rows={4} className="form-control " required placeholder="Message" value={formState.message || ''} onChange={changeHandler} />
                                                </div> */}

                                                <div className="form-group">
                                                <label htmlFor="name">Name:</label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name" className="form-control" placeholder="Name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                </div>
                                                <div className="form-group">
                                                <label htmlFor="email">Email:</label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email" className="form-control" placeholder="Email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                </div>
                                                <div className="form-group">
                                                <label htmlFor="message">Message:</label>
                                                <textarea
                                                    id="message"
                                                    name="message" className="form-control " placeholder="Message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                </div>
                                                <div>
                                                <p>
                                                    <strong>Privacy</strong>
                                                    <span className="wpcf7-form-control-wrap EU-DSGVO">
                                                    <span className="wpcf7-form-control wpcf7-acceptance">
                                                    <span className="wpcf7-list-item">
                                                    <label><input type="checkbox" name="EU-DSGVO" value="1" aria-invalid="false" style={{opacity:1}}/>
                                                    <span className="wpcf7-list-item-label">I agree that my details from the contact form will be collected and processed to answer my request. The data is saved or deleted on request.
                                                    Note: You can revoke your consent at any time for the future by sending an e-mail to contact@gewisrenew.com Detailed information on handling user data can be found in our <a href="https://gewis.in/privacy-policy/">privacy policy</a>.</span>
                                                    </label>
                                                    </span>
                                                    </span>
                                                    <span className="w-form-row-field-bar"></span>
                                                    </span>
                                                
                                                </p>
                                                </div>

                                                <div className="text-right">
                                                    <button name="submit" type="submit" value="Send Email" className="site-button btn-effect" >submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="contact-info m-b30">
                                            {/* TITLE START */}
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Contact</span> Info</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="bg-dark p-a20 text-white">
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                        <p>(+91) 20-67122400</p>
                                                        <p>(+91) 9112249982</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Email address</h5>
                                                        <p><a href="mailto:contact@gewisrenew.com" style={{color:"white"}}>contact@gewisrenew.com</a></p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left">
                                                    <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Address info</h5>
                                                        <p>GeWiS Renewpower - Solar EPC Company, 705-707, Nyati Emporius,Radha Chowk,Off Mumbai-Banglore Bypass,Baner, Pune-411045, India</p>
                                                    </div>
                                                </div>
                                           
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                                             
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                    <div>
                                <iframe
                                    title="GeWiS Renewpower Location"
                                    width="100%"
                                    height="450"
                                    frameBorder="0"
                                    style={{ border: 0 }}
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.559371480538!2d73.766224!3d18.567195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd8851041805bf53a!2sGeWiS%20Renewpower!5e0!3m2!1sen!2sus!4v1642123456789!5m2!1sen!2sus"
                                    allowFullScreen
                                ></iframe>
                            </div>
                             {/* Popup HTML */}
                            <div id="popup" className="popup">
                                <div className="popup-content">
                                    <p>Mail sent successfully!</p>
                                    {/* Button to close the popup */}
                                    <button onClick={() => document.getElementById('popup').classList.remove('show-popup')}>OK</button>
                                </div>
                            </div>
                    </div>


                <Footer />

            </>
        );
   // };
};

export default ContactForm;