import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer2';
import SimilarProjects from '../Elements/SimilarProjects';
import Banner from '../Elements/Banner';
import ReactPlayer from 'react-player';

var bnrimg = require('./../../images/banner/5.jpg');

class ProjectDetail extends React.Component {
    
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Helping you and your house become better acquainted." pagename="Project Detail" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                            <div className="project-detail-outer">
                                <div className="m-b0">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/appl1.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/appl3.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div className="project-detail-pic m-b30">
                                                <div className="mt-media">
                                                    <img src={require('./../../images/projects/portrait/appl2.jpg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-b30">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="product-block">
                                                <div className="row">
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Plant Capacity:</h4>
                                                        <p>148.17 kWp</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Plant Location:</h4>
                                                        <p>Pune, Maharsahtra</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Installed on:</h4>
                                                        <p>GI sheet rooftop of the factory</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Annual Power Generation:</h4>
                                                        <p>2,10,700 kwh</p>
                                                    </div>
                                                    <div className="col-md-12 m-b10">
                                                        <h4 className="text-uppercase font-weight-600 m-b10">Carbon dioxide emissions mitigated:</h4>
                                                        <p>4556 tonnes</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="mt-box">
                                                <div className="mt-thum-bx mt-img-overlay1 mt-img-effect">
                                                   <img src={require('./../../images/gallery/s7.jpg')} alt="" />
                                                    {/* <button type="button" className="play-now" data-toggle="modal" data-target="#myModal3">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-detail-containt">
                                    <div className="bg-white text-black">
                                        <h3 className="font-weight-600">APPL GOR Plastics India Pvt. Ltd. – Pune</h3>
                                        <p>APPL GOR Plastics India Private Limited is all about green ancillary products in automotive which are environmentally friendly and lead to zero recycled wastage. GeWiS Renewpower has installed a 148 kWp solar PV rooftop power plant on the metal sheet rooftop in their Koregaon Bhima, Pune facility. The plant is spread over an area of 1900 sq. mtrs.</p>
                                        <p>The Solar rooftop plant is amongst APPL’s one of the steps towards Green initiatives. The installed solar power plant is helping in reducing about 415 tonnes of carbon emissions annually.</p>
                                        <p>The solar power plant was installed to meet the enormous energy demand required for manufacturing. The plant is generating about 2 lakh units of energy annually and is taking up about 20% of the factory’s total energy requirement.</p>
                                        <p>GeWiS has installed the solar power plant on the facility’s rooftop with multicrystalline modules from Renewsys; Multi MPPT inverters from Growatt and BOS from reputed manufactures.</p>
                                        <p>The solar plant is installed under the ‘CAPEX’ form of financial model.</p>
                                        <p>GeWiS is also taking care of the operation and maintenance of the solar power plant. GeWiS has installed the required safety equipment like safety lines and walkways which provides easy access to solar plants.</p>
                                        <p>Indigenously developed data logger and Irradiation and temperature sensor by GeWiS has been set up in the facility. This helps in online monitoring of generated power.</p>
                                        
                                        <div className="m-b0">
                                            <div className="mt-divider divider-1px  bg-black"><i className="icon-dot c-square" /></div>
                                        </div>
                                        <ul className="social-icons social-square social-darkest m-b0">
                                            <li><NavLink to={"#"} className="fa fa-facebook" /></li>
                                            <li><NavLink to={"#"} className="fa fa-twitter" /></li>
                                            <li><NavLink to={"#"} className="fa fa-linkedin" /></li>
                                            <li><NavLink to={"#"} className="fa fa-rss" /></li>
                                            <li><NavLink to={"#"} className="fa fa-youtube" /></li>
                                            <li><NavLink to={"#"} className="fa fa-instagram" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                    <SimilarProjects/>
                </div>

                <div className="modal fade" id="myModal3" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://www.youtube.com/watch?v=s3A7AK1K2hc' />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default ProjectDetail;